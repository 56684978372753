<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">Home <span>||</span></li>
                        <li class="list-inline-item"><a href="#">About Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- About Area -->
<section class="about-us">
    <div class="container">
        <div class="row">
            <span style="font-size: 13px" [innerHTML]="contentdata"></span>
        </div>
    </div>
</section>
<!-- End About Area -->
