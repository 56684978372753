import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http:HttpClient) { }
  getCartViewdata(){
    let url = environment.base_url;
    var postData = {
      user_id:localStorage.getItem('user_id'),
      coupon_code:localStorage.getItem('coupon_code')
    }
    return this.http.post(url+'get-cart-detail',postData);
  }

  getUserAddress(){
    let url = environment.base_url;

    var postData = {
      user_id:localStorage.getItem('user_id')
    }
    return this.http.post(url+'get-user-address',postData);
  }

  getState(){
    let url = environment.base_url;
    return this.http.get(url+'get-state ');
  }
  getCitybyStateId(state_id:string){
    let url = environment.base_url;
    var postData = {
      state_id:state_id 
    }
    return this.http.post(url+'get-city',postData);
  }

  getOtherType(){
    let url = environment.base_url;
    var postData = {
      user_id:localStorage.getItem('user_id')
    }
    return this.http.post(url+'get-user-address-type',postData);
  }


  getSlotDate(){
    let url = environment.base_url;
    return this.http.get(url+'get-slot-date');
  }
  getSlot(slotdate:any){
    let url = environment.base_url;
    let postdata = {
      slot_date:slotdate
       }
    return this.http.post(url+'get-slot',postdata);
  }
}
