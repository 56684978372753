<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="breadcrumb-box text-center">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><a href="#">Home</a></li>
            <li class="list-inline-item"><span>||</span> Product Detail</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Single Product Area -->
<section class="sg-product">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <!-- Slider Area -->
            <app-commonallcategory></app-commonallcategory>
            <!-- End Slider Area -->
          </div>
          <div class="col-md-4 contiu" *ngIf="productdata?.data != undefined">
            <div class="sg-img">
              <div class="tab-content">
                <div
                  *ngIf="productdata?.data?.image"
                  class="tab-pane active"
                  id="sg0"
                  role="tabpanel"
                >
                  <a
                    id="pimage"
                    class="not-active"
                    href="{{ productdata.data.image }}"
                  >
                    <img
                      id="pimage"
                      src="{{ productdata.data.image }}"
                      alt=""
                      class="img-fluid"
                  /></a>
                </div>

                <!-- <div class="tab-pane" role="tabpanel" *ngFor="let item1 of productdata.product_image" id="sg{{item1.id}}"> 
                                            <div class="sk">sg{{item1.id}}</div>
                                            <img src="{{item1.image}}" alt="" class="img-fluid">
                                        </div> -->
              </div>
              <div class="nav d-flex" *ngIf="!productImageSrc">
                <a
                  *ngIf="productdata?.data?.image"
                  style="margin-right: 5px"
                  class="nav-item nav-link active"
                  ><img
                    src="{{ productdata?.data?.image }}"
                    alt=""
                    onclick="setImageSrc(this);"
                /></a>

                <ng-container *ngIf="productdata?.product_image">
                  <a
                    style="margin-right: 5px"
                    class="nav-item nav-link"
                    *ngFor="let item11 of productdata?.product_image"
                    id="sg{{ item11.id }}"
                  >
                    <img
                      src="{{ item11.image }}"
                      alt=""
                      onclick="setImageSrc(this);"
                    />
                  </a>
                </ng-container>
              </div>

              <div class="nav d-flex" *ngIf="productImageSrc">
                <a
                  *ngIf="productImageSrc"
                  style="margin-right: 5px"
                  class="nav-item nav-link active"
                  ><img
                    src="{{ productImageSrc }}"
                    alt=""
                    onclick="setImageSrc(this);"
                /></a>

                <!-- <ng-container *ngIf="productImageSrc.length>0">
                  <a
                  style="margin-right: 5px"
                  class="nav-item nav-link"
                  *ngFor="let item11 of productdata?.product_image"
                  id="sg{{ item11.id }}"
                >
                  <img
                    src="{{ item11.image }}"
                    alt=""
                    onclick="setImageSrc(this);"
                  />
                </a>
                </ng-container> -->
              </div>
              <!-- <div class="exzoom hidden" id="exzoom">
                                    <div class="exzoom_img_box">
                                        <ul class='exzoom_img_ul'>
                                            <li><img src="https://picsum.photos/270/270/?random"/></li>
                                            <li><img src="https://picsum.photos/320/320/?random"/></li>
                                            <li><img src="https://picsum.photos/600/600/?random"/></li>
                                            <li><img src="https://picsum.photos/500/500/?random"/></li>
                                            <li><img src="https://picsum.photos/700/700/?random"/></li>
                                            <li><img src="https://picsum.photos/310/310/?random"/></li>
                                            <li><img src="https://picsum.photos/410/410/?random"/></li>
                                            <li><img src="https://picsum.photos/400/400/?random"/></li>
                                        </ul>
                                    </div>
                                    <div class="exzoom_nav"></div>
                                    <p class="exzoom_btn">
                                        <a href="javascript:void(0);" class="exzoom_prev_btn"> < </a>
                                        <a href="javascript:void(0);" class="exzoom_next_btn"> > </a>
                                    </p> -->
            </div>
          </div>
          <div class="col-md-4" *ngIf="productdata?.data != undefined">
            <div class="sg-content">
              <div class="pro-tag">
                <ul class="list-unstyled list-inline">
                  <li class="list-inline-item">
                    <a href="#" *ngIf="productdata?.data?.category"
                      >{{ productdata?.data?.category }} ,</a
                    >
                  </li>
                  <li class="list-inline-item">
                    <a href="#" *ngIf="productdata?.data?.subcategory">{{
                      productdata?.data?.subcategory
                    }}</a>
                  </li>
                </ul>
              </div>
              <div class="pro-name">
                <p *ngIf="productdata?.data?.product_name">
                  {{ productdata?.data?.product_name }}
                </p>
              </div>
              <div class="pro-price">
                <ul
                  class="list-unstyled list-inline"
                  *ngIf="productdata?.data != undefined"
                >
                  <li
                    class="list-inline-item"
                    *ngIf="!offer_price && productdata.data.offer_price"
                  >
                    {{ productdata.data.offer_price }}
                  </li>
                  <li class="list-inline-item" *ngIf="offer_price">
                    {{ offer_price }}
                  </li>
                  <li class="list-inline-item">
                    <span
                      *ngIf="
                        productdata?.data?.offer_price !=
                          productdata?.data?.price &&
                        !price &&
                        productdata?.data
                      "
                      >{{ productdata.data.price }}</span
                    >
                    <span *ngIf="price && price!=offer_price">{{ price }}</span>
                  </li>
                </ul>
                <p>
                  Availability : <span >{{checkAddStock()?'In Stock':'Out Stock' }}</span>
                </p>
              </div>
              <div class="colo-siz">
                <div class="color" *ngIf="!productdata.data.isgrocerry">
                  <ul
                    class="list-unstyled list-inline"
                    *ngIf="productdata.data.color"
                  >
                    <li>Color :</li>

                    <li class="list-inline-item">
                      <label for="color-1">{{ productdata.data.color }}</label>
                    </li>
                  </ul>
                  <ng-container
                    *ngIf="
                      !productdata.data.color && productdata?.attributeList
                    "
                  >
                    <ul
                      class="list-unstyled list-inline"
                      *ngFor="let attrList of productdata?.attributeList"
                    >
                      <ng-container *ngIf="attrList.name == 'Color'">
                        <li>{{ attrList.name }} :</li>

                        <li class="list-inline-item">
                          <select
                            (change)="changeAmount($event, 'changeColor')"
                          >
                            <option
                              *ngFor="let s of attrList?.attribute_values"
                              [value]="s.id"
                            >
                              {{ s.attribute_value }}
                            </option>
                          </select>
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </div>
                <div class="size">
                  <ul class="nav nav-pills">
                    <li
                      class="nav-item"
                      *ngFor="let s of productdata.data.size"
                    >
                      <a
                        class="nav-link"
                        data-toggle="pill"
                        style="border: 1px solid lightgray; cursor: pointer"
                        data-value="1"
                        (click)="get_size(productdata.data.unit, s)"
                        >{{ s }} {{ productdata.data.unit }}</a
                      >
                    </li>
                    <ng-container
                      *ngFor="let attrList of productdata?.attributeList"
                    >
                      <ng-container
                        *ngIf="
                          !productdata.data.size && attrList.name != 'Color'
                        "
                      >
                        <li
                          class="nav-item"
                          *ngFor="
                            let s of attrList?.attribute_values;
                            let i = index
                          "
                        >
                          <a
                            [ngClass]="i == 0 ? 'active' : ''"
                            class="nav-link"
                            data-toggle="pill"
                            style="border: 1px solid lightgray; cursor: pointer"
                            data-value="1"
                            (click)="changeAmount(s.id, 'changeSize')"
                            >{{ s.attribute_value }}</a
                          >
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                  <!-- <ul class="list-unstyled list-inline">
                                        <li>Size :</li>
                                        <li class="list-inline-item">
                                            <label for="color-1" *ngFor="let s of productdata.data.size">
                                                {{s}} {{productdata.data.unit}}
                                            </label>
                                        </li>

                                    </ul> -->
                </div>

                <ng-container *ngIf="checkAddStock()">
                <div class="qty-box">
                  <ul class="list-unstyled list-inline">
                    <li class="list-inline-item">Qty :</li>
                    <li class="list-inline-item quantity buttons_added">
                      <input type="button" value="-" class="minus" />
                      <input
                        type="number"
                        step="1"
                        min="1"
                        max="10"
                        value="{{ productdata.cartqty }}"
                        #qty
                        class="qty text"
                        size="4"
                        readonly
                      />
                      <input type="button" value="+" class="plus" />
                    </li>
                  </ul>
                  <!-- <a class="fav-com nowhiden pro-btns" data-toggle="tooltip" data-placement="top" title="Wishlist" (click)="addToWishlist(productdata.data.id)" style="cursor: pointer;"><img src="assets/images/it-fav.png" alt=""></a> -->
                </div>
                <div class="pro-btns">
                  <button
                    class="cart-btns cart btn btn-warning btn-warning1"
                    (click)="bynowclick(productdata.data.id, qty.value)"
                    style="margin-right: 10px"
                  >
                    Buy Now
                  </button>
                  <button
                    class="cart btn btn-warning"
                    (click)="lgbtnclick(productdata.data.id, qty.value)"
                    style="margin-right: 10px"
                  >
                    Add to cart
                  </button>
                  <a
                    class="fav-com nowhiden"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Wishlist"
                    (click)="addToWishlist(productdata.data.id)"
                    style="cursor: pointer"
                    ><img src="assets/images/it-fav.png" alt=""
                  /></a>
                </div>
              </ng-container>
                <!-- <div class="pro-btns" *ngIf="checkOutStock()">
                  <button
                    class="cart btn btn-danger"
                    style="margin-right: 10px">
                    Out Of Stock
                  </button>
                </div> -->

                <div class="see-more-sellers" *ngIf="venderList?.length>1">
                  <a href="allSellersList">See More Sellers</a>
                </div>
                <!-- <div class="">

                                </div> -->
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="sg-tab">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#pro-det"
                    >Product Details</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#rev"
                    >Reviews ({{ rlength }})</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div
                  class="tab-pane fade show active"
                  id="pro-det"
                  role="tabpanel"
                >
                  <span
                    style="font-size: 13px"
                    [innerHTML]="productdata.data.product_description"
                  ></span>
                </div>
                <div class="tab-pane fade" id="rev" role="tabpanel">
                  <div
                    class="review-box d-flex"
                    *ngFor="let r of revewdata.data"
                  >
                    <div class="rv-img">
                      <img src="assets/images/avatar.png" alt="" />
                    </div>
                    <div class="rv-content">
                      <h6>
                        {{ r.fname }} {{ r.lname }}
                        <span>({{ r.created_at }})</span>
                      </h6>
                      <ul class="list-unstyled list-inline">
                        {{
                          r.rating
                        }}
                        <li class="list-inline-item">
                          <i class="fa fa-star"></i>
                        </li>
                      </ul>
                      <p>{{ r.reviews }}</p>
                    </div>
                  </div>
                  <div class="review-form">
                    <h6>Add Your Review</h6>
                    <form
                      #reviewPost="ngForm"
                      (ngSubmit)="onReview(reviewPost)"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="star-rating">
                            <label>Your Rating*</label>
                            <span class="fa fa-star-o" data-rating="1"></span>
                            <span class="fa fa-star-o" data-rating="2"></span>
                            <span class="fa fa-star-o" data-rating="3"></span>
                            <span class="fa fa-star-o" data-rating="4"></span>
                            <span class="fa fa-star-o" data-rating="5"></span>
                            <input
                              type="hidden"
                              name="rating"
                              class="rating-value"
                              value="0"
                              ngModel="0"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label>Your Review*</label>
                          <textarea
                            name="review"
                            required=""
                            ngModel
                          ></textarea>
                          <button type="submit">Add Review</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="sim-pro">
              <div class="sec-title">
                <h5>Similar Product</h5>
              </div>
              <div class="sim-slider owl-carousel" *ngIf="isproductexist">
                <div
                  class="sim-item"
                  *ngFor="let item1 of productdata.similerproduct"
                >
                  <div class="sim-img">
                    <a href="product-details/{{ item1.id }}"
                      ><img
                        class="main-img img-fluid"
                        src="{{ item1.image }}"
                        alt=""
                    /></a>
                    <div class="layer-box">
                      <a
                        class="it-fav"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Favourite"
                        (click)="addToWishlist(item1.id)"
                        style="cursor: pointer"
                        ><img src="assets/images/it-fav.png" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="sim-heading">
                    <p>
                      <a href="product-details/{{ item1.id }}">{{
                        item1.product_name
                      }}</a>
                    </p>
                  </div>
                  <div class="img-content d-flex justify-content-between">
                    <div>
                      <ul class="list-unstyled list-inline price">
                        <li class="list-inline-item">
                          {{ item1.offer_price }}
                        </li>
                        <li class="list-inline-item">
                          <span
                            style="
                              background: none;
                              border: none;
                              border-radius: 0;
                              text-decoration: line-through;
                            "
                            *ngIf="item1.offer_price != item1.price"
                            >{{ item1.price }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span data-placement="top" title="Add to Cart"
                        ><img
                          src="assets/images/it-cart.png"
                          (click)="callFunction(item1.id, 1)"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isproductexist">No Similer Product</div>
            </div>
          </div>
          <!-- code by rajan -->
          <div class="col-md-12 mt-5">
            <app-recentproduct></app-recentproduct>
          </div>
          <!-- code by rajan -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Single Product Area -->
