 <!-- Breadcrumb Area -->
 <section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a routerLink="#">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Wishlist</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Wishlist -->
<section class="shopping-cart">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cart-table wsh-list table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="t-pro">Product</th>
                                <th class="t-price">Price</th>
                                <th class="t-qty">Stock</th>
                                <th class="t-total">Move To Cart</th>
                                <th class="t-rem"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="showBlank">
                            <tr *ngFor="let item of wishlist">
                                <td class="t-pro d-flex">
                                    <div class="t-img">
                                        <a routerLink="#"><img src="{{item.image}}" alt="" style="width: 50px;"></a>
                                    </div>
                                    <div class="t-content">
                                        <p class="t-heading"><a routerLink="#">{{item.product_name}}</a></p>
                                        <ul class="list-unstyled col-sz">
                                            <li *ngIf="!item.isgrocerry"><p>Color : <span>{{item.color}}</span></p></li>
                                            <li><p>Size : <span>{{item.size}} {{item.unit}}</span></p></li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="t-price">{{item.offer_price}}</td>
                                <td class="t-stk">{{item.stock_status}}</td>
                                <td class="t-add"><button type="button" name="button" (click)="moveToCart(item.id);">Move to Cart</button></td>
                                <td class="t-rem"><i class="fa fa-trash-o" (click)="removeFromWishlist(item.id);"></i></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!showBlank">
                               <tr> 
                                   <td colspan="4" class="text-center">No data available</td>
                               </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Wishlist -->


