
        <style type="text/css">
          img{
              width: 100px;
              height: 100px;
          }
          .jumbotron{
              margin-top: 100px;
          }
          i.custom {font-size: 55px; color: rgb(17, 13, 9);
          margin-right: 10px;}
              
          </style>
<div class="container" >
    <div class="jumbotron text-center">
        
        <div style="display: inline-flex;">
        
            <i class="fa fa-rupee custom"></i>
        <h1 style="font: size 500px;">{{paymentJson.grand_total}}</h1>
    </div> <br>
        <img src="assets/images/rght.png" alt="" height="300px" width="200px"> <br> <br>
        <button class=" btn-primary btn-lg" style="background-color: rgb(243, 92, 4); text-decoration: none;" (click)="pay()"> Pay Now</button>
    </div>
</div>