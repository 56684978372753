import { Component, OnInit } from '@angular/core';
import {HomepageService} from '../service/homepage.service';
@Component({
  selector: 'app-shippingpolicy',
  templateUrl: './shippingpolicy.component.html',
  styleUrls: ['./shippingpolicy.component.css']
})
export class ShippingpolicyComponent implements OnInit {
  contentdata:any;
  constructor(private homepageService:HomepageService) { }

  ngOnInit(): void {
    this.homepageService.getShippingPolicy().toPromise().then((hdata:any)=>{
      this.contentdata=hdata.data[0]['content'];
    });
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
}
