<div class="text-center" style="padding:50px 0">
	
	<!-- Main Form -->
	<div class="login-form-1">
		<h5 class="otpverification" style="margin-bottom:10px;">Enter your mobile no</h5>
		<form id="login-form"  class="text-left" #sendPost="ngForm" (ngSubmit)="onSubmit(sendPost.value,isHidden)">
			<div class="login-form-main-message"></div>
			<div class="main-login-form">
				<div class="login-group">
					<div class="form-group">
						<input type="text" class="form-control" id="phone" name="phone" placeholder="Please Enter Your Mobile" ngModel>
					</div>
					<div class="form-group" *ngIf="isHidden">
						<input type="text" class="form-control" id="otp" name="otp" placeholder="Please Enter Your Otp" ngModel>
					</div>
				</div>
				<div class="form-group" style="margin-top:10px;" *ngIf="isHidden; else isShow">
					<button type="submit" class="btn btn-success" style="width: 100%;">Verify</button>
					<span class="btn btn-primary btn-xs" style="cursor: pointer;margin-top: 10px;" (click) = "resendOtp()">Resend Otp</span>
				</div>
				<ng-template #isShow>
					<div class="form-group" style="margin-top:10px;">
						<button type="submit" class="btn btn-success" style="width: 100%;">Send</button>
					</div>
				  </ng-template>
			</div>
		</form>
	</div>
	<!-- end:Main Form -->
</div>

