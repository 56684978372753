import { Component, OnInit } from '@angular/core';
import {LoginService} from '../service/login.service';
import {Router} from '@angular/router';
import { CommonService } from '../service/common.service';
// import { AuthloginService } from '../service/authlogin.service';
// import { GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private loginService:LoginService,
    private router: Router,
    private common:CommonService
    ) { }
  userdata:any;
  user_id:any;
  user_name:any;

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
  onSubmit(data: any,)
  {
    this.loginService.loginUser(data).toPromise().then((cdata:any)=>{

      if(cdata.status == false)
      {
        this.common.showError("",cdata.msg);
      }
      else
      {
        localStorage.setItem('user_id', cdata.data.user_id);
        localStorage.setItem('user_name', cdata.data.fname);
        this.user_id = cdata.data.user_id;
        this.user_name = cdata.data.user_name;
        if (localStorage.getItem("redirect_from") === 'detail')
        {
          let pid = localStorage.getItem("redirect_pid");
          this.router.navigate(['/product-details/'+pid])
          .then(() => {
            window.location.reload();
          });
        }
        else
        {
          this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
        }
      }
    });
  }

  googleLogin(){
    this.loginService.signInWithGoogle()

  }

  facebookLogin(){
    this.loginService.signInWithFB();
  }


}



// <div *ngIf="user">
// <img src="{{ user.photoUrl }}">
// <div>
//   <h4>{{ user.name }}</h4>
//   <p>{{ user.email }}</p>
// </div>
// </div>


// <div class="row">
//   <div class="col-lg-4 col-md-10 col-xs-12 col-sm-6 offset-3"> <button (click)="signInWithGoogle()" class="btn btn-primary google-plus"> <span>Login with Facebook</span> <i class="fa fa-google-plus"></i> </button> </div>
//   <div class="col-lg-4 col-md-2 col-xs-12 col-sm-6"> <button (click)="signInWithFB()" class="btn btn-primary facebook"> Login with Google <i class="fa fa-facebook"></i> </button> </div>
//   <div class="col-lg-4 col-md-2 col-xs-12 col-sm-6"> <button (click)="signOut()" class="btn btn-primary "> Logout <i class="fa fa-sign-out"></i> </button> </div>
//   <div class="col-lg-4 col-md-2 col-xs-12 col-sm-6"> <button (click)="showData()" class="btn btn-primary "> testing <i class="fa fa-times-rectangle"></i> </button> </div>
//</div>
