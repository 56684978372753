import { Component, OnInit } from '@angular/core';
import {BlogService} from '../service/blog.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogdata:any;
  constructor(private blogService:BlogService) { }

  ngOnInit(): void {
    this.blogService.getBlogList().toPromise().then((bdata:any)=>{
      this.blogdata=bdata.data;
    });
  }

}
