<!-- Slider Area -->
<section class="slider-area">
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0 d-lg-block m-0 mobilecategorylist">
                <div class="menu-widget">
                    <p><i class="fa fa-th-large" aria-hidden="true"></i> All Categories</p>
                    <ul class="list-unstyled">
                        <li *ngFor="let item of categorydata.data">
                            <a routerLink="product-list/by-category/{{item.id}}">
                                <img src="" alt="">{{item.category}}<i class="fa fa-angle-right" *ngIf="item.issubcategory"></i></a>
                            <div class="mega-menu" *ngIf="item.issubcategory">
                                <div class="menu-widget" *ngFor="let subcategory of item.subcategory" class="list-unstyled">
                                    <a routerLink="product-list/by-subcategory/{{subcategory.id}}">
                                        <h6>{{subcategory.subcategory}}</h6>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-fix-l20">
                <div class="owl-carousel owl-slider">
                    <div *ngFor="let sl of sliderdata.data" class="slider-item slider-item1">
                        <a href="{{sl.url}}"><img src="{{sl.slider_image}}" alt="" class="img1 wow fadeInRight effect" data-wow-duration="1s" data-wow-delay="0s"></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- End Slider Area -->

<!-- Product Area-->
<section class="product-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <!-- code by rajan -->
                    <div class="col-md-12 padding-fix-l20">
                        <div class="new-product">
                            <div class="sec-title">
                                <h5 class="p-heading">Recent Products</h5>
                            </div>
                            <div class="new-sliders owl-carousel">
                                <div class="new-item" *ngFor="let tre of homepageproduct?.recent">
                                    <!-- <div class="tab-heading">
                                        <ul class="list-unstyled list-inline">
                                            <li class="list-inline-item"><a routerLink="#">{{tre.category}},</a></li>
                                            <li class="list-inline-item"><a routerLink="#">{{tre.subcategory}}</a></li>
                                        </ul>

                                    </div> -->
                                    <div class="new-img">
                                        <a href="product-details/{{tre.id}}"><img class="prod-img main-img img-fluid" src="{{tre.image}}" alt=""></a>
                                    </div>
                                    <!-- <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{tre.id}}">{{tre.product_name}}</a></p> -->

                                    <div class="img-content d-flex justify-content-between">
                                        <!-- <div class="p-price">
                                            <ul class="list-unstyled list-inline price">
                                                <li class="list-inline-item">₹{{tre.offer_price}}</li>
                                                <li class="list-inline-item">
                                                    <p *ngIf="tre.offer_price != tre.price">₹{{tre.price}}</p>
                                                </li>
                                            </ul>
                                        </div> -->
                                        <div class="p-by">
                                            <!-- <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(tre.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span> -->
                                            <!-- <span data-toggle="tooltip" data-placement="top" title="Add to Cart" (click)="addToCart(tre.id)"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End code by rajan -->
                    <div class="col-md-12 padding-fix-l20">
                        <div class="ftr-product">
                            <div class="tab-box d-flex justify-content-between">
                                <div class="sec-title">
                                    <h5 class="p-heading">Feature Product {{wcount}}</h5>
                                </div>
                            </div>
                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="all" role="tabpanel">
                                    <div class="tab-slider owl-carousel">
                                        <div class="tab-item" *ngFor="let fet of homepageproduct.featured">
                                            <!-- <div class="tab-heading">
                                                <ul class="list-unstyled list-inline">
                                                    <li class="list-inline-item"><a routerLink="#">{{fet.category}},</a></li>
                                                    <li class="list-inline-item"><a routerLink="#">{{fet.subcategory}}</a></li>
                                                </ul>
                                                <p class="txtlimit">
                                                    <a href="product-details/{{fet.id}}">{{fet.product_name}}</a>
                                                </p>
                                            </div> -->  
                                            <div class="tab-img">
                                                <a href="product-details/{{fet.id}}"><img  class="main-img img-fluid home-page-img" src="{{fet.image}}" alt=""></a>
                                            </div>
                                            <!-- <p class="txtlimit">
                                                <a href="product-details/{{fet.id}}">{{fet.product_name}}</a>
                                            </p> -->
                                            <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{fet.id}}">{{fet.product_name}}</a></p>

                                            <div class="img-content d-flex justify-content-between">
                                                <div class="p-price">
                                                    <ul class="list-unstyled list-inline price">
                                                        <li class="list-inline-item">₹{{fet.offer_price}}</li>
                                                        <li class="list-inline-item">
                                                            <p *ngIf="fet.offer_price != fet.price">₹{{fet.price}}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="p-by">
                                                    <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(fet.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
                                                    <!-- <span class="it-fav" data-placement="top" title="Add to Cart" (click)="addToCart(fet.id)"> <i class="fa fa-shopping-cart" aria-hidden="true"></i></span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-fix-l20">
                        <div class="row">
                            <div class="col-md-6" *ngFor="let bn of bannerdata.centerbanner">
                                <div class="banner">
                                    <a href="{{bn.url}}"><img src="{{bn.banner_image}}" alt="" class="img-fluid"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 padding-fix-l20">
                        <div class="new-product">
                            <div class="sec-title">
                                <h5 class="p-heading">Trending Product</h5>
                            </div>
                            <div class="new-slider owl-carousel">
                                <div class="new-item" *ngFor="let tre of homepageproduct.trending">
                                    <!-- <div class="tab-heading">
                                        <ul class="list-unstyled list-inline">
                                            <li class="list-inline-item"><a routerLink="#">{{tre.category}},</a></li>
                                            <li class="list-inline-item"><a routerLink="#">{{tre.subcategory}}</a></li>
                                        </ul>

                                    </div> -->
                                    <div class="new-img">
                                        <a href="product-details/{{tre.id}}"><img  class="trend-prod-img main-img img-fluid" src="{{tre.image}}" alt=""></a>
                                    </div>
                                    <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{tre.id}}">{{tre.product_name}}</a></p>

                                    <div class="img-content d-flex justify-content-between">
                                        <div class="p-price">
                                            <ul class="list-unstyled list-inline price">
                                                <li class="list-inline-item">₹{{tre.offer_price}}</li>
                                                <li class="list-inline-item">
                                                    <p *ngIf="tre.offer_price != tre.price">₹{{tre.price}}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="p-by">
                                            <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(tre.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
                                            <!-- <span data-toggle="tooltip" data-placement="top" title="Add to Cart" (click)="addToCart(tre.id)"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-12 padding-fix-l20">
                        <div class="banner-two" *ngFor="let bnb of bannerdata.bottombanner">
                            <a href="{{bnb.url}}"><img src="{{bnb.banner_image}}" alt="" class="img-fluid" style="width: 100%;"></a>
                        </div>
                    </div>
                    <!-- code by rajan -->
                    <!-- <div class="col-md-12 padding-fix-l20">
                        <div class="new-product">
                            <div class="sec-title">
                                <h5 style="font-size: 20px;">Oil and Ghee</h5>
                            </div>
                            <div class="new-slider owl-carousel">
                                <div class="new-item" *ngFor="let catp of category_product">

                                    <div class="new-img">
                                        <a href="product-details/{{catp.id}}"><img style="width: 221.333px" class="main-img img-fluid" src="{{catp.image}}" alt=""></a>
                                    </div>
                                    <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{catp.id}}">{{catp.product_name}}</a></p>

                                    <div class="img-content d-flex justify-content-between">
                                        <div class="p-price">
                                            <ul class="list-unstyled list-inline price">
                                                <li class="list-inline-item">₹{{catp.offer_price}}</li>
                                                <li class="list-inline-item">
                                                    <p *ngIf="catp.offer_price != catp.price">₹{{catp.price}}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="p-by">
                                            <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(catp.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- code by rajan -->
                    <!-- <div class="col-md-12 padding-fix-l20">
                        <div class="new-product">
                            <div class="sec-title">
                                <h5 style="font-size: 20px;">Hair Care</h5>
                            </div>
                            <div class="new-slider owl-carousel">
                                <div class="new-item" *ngFor="let catp1 of category_product1">
                                    <div class="new-img">
                                        <a href="product-details/{{catp1.id}}"><img style="width: 221.333px" class="main-img img-fluid" src="{{catp1.image}}" alt=""></a>
                                    </div>
                                    <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{catp1.id}}">{{catp1.product_name}}</a></p>

                                    <div class="img-content d-flex justify-content-between">
                                        <div class="p-price">
                                            <ul class="list-unstyled list-inline price">
                                                <li class="list-inline-item">₹{{catp1.offer_price}}</li>
                                                <li class="list-inline-item">
                                                    <p *ngIf="catp1.offer_price != catp1.price">₹{{catp1.price}}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="p-by">
                                            <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(catp1.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-12 padding-fix-l20">
                        <div class="hm-blog">
                            <div class="sec-title">
                                <h5 class="p-heading">Latest News</h5>
                            </div>
                            <div class="blog-slider owl-carousel">
                                <div class="blog-item" *ngFor="let blg of blogdata.data">
                                    <div class="blog-img">
                                        <a routerLink="blog/blog-details/{{blg.id}}"><img src="{{blg.image}}" alt="" class="img-fluid"></a>
                                    </div>
                                    <div class="blog-content">
                                        <h6><a routerLink="blog/blog-details/{{blg.id}}">{{blg.title}}</a></h6>
                                        <ul class="list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fa fa-calendar"></i>{{blg.created_at}}</li>
                                        </ul>
                                        <p>{{blg.short_description}}</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="tp-bnd owl-carousel">
                    <div class="bnd-items" *ngFor="let brnd of branddata.data">
                        <img src="{{brnd.image}}" alt="" class="img-fluid">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Product Area -->