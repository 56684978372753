 <!-- Breadcrumb Area -->
 <section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="#">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Products</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->
<!-- Category Area -->
<section class="category">
    <div class="container">
        <div class="row">
            <div class="col-md-2" style="padding-left: 0px !important;">
                    <div class="sec-title">
                        <h3><b>Brands</b></h3>
                    </div>
                    <div class="brand-box">
                        <ul class="list-unstyled">
                            <li class="form-check" *ngFor="let brnd of productdata.brand">
                                <input class="form-check-input" type="checkbox" [value]="brnd.id" (change)="onCheckboxChange($event,brnd.id)" [checked]="brnd.checked">
                                <label for="{{brnd.brand_name}}">{{brnd.brand_name}}</label>
                            </li>
                        </ul>
                </div>
            </div>
            <div class="col-md-10">
                <div class="product-box">
                    <div class="cat-box d-flex justify-content-between newmodify">
                            Sort By : 
                        <div class="show-item">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" (click)="getProductList('new')">Newest First</a>
                              </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" (click)="getProductList('price_low_to_high')">Price - Low to High</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" (click)="getProductList('price_high_to_low')">Price - High to Low</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="grid" role="tabpanel">
                            <div class="row" *ngIf="isNodata">
                                <div class="col-lg-3 col-md-6 col-6" *ngFor="let item of productdata.data">
                                    <div class="tab-item">
                                        <div class="tab-img">
                                            <a href="product-details/{{item.id}}"><img class="main-img img-fluid" src="{{item.image}}" alt=""></a>
                                            <div class="layer-box">
                                                <a class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(item.id)" style="cursor: pointer;"><img src="assets/images/it-fav.png" alt=""></a>
                                            </div>
                                        </div>
                                        <div class="tab-heading">
                                            <p class="txtlimit" title="{{item.product_name}}"><a href="product-details/{{item.id}}">{{item.product_name}}</a></p>
                                        </div>
                                        <div class="img-content d-flex justify-content-between">
                                            <div>
                                                <ul class="list-unstyled list-inline price">
                                                    <li class="list-inline-item">₹{{item.offer_price}}</li>
                                                    <li class="list-inline-item"><p *ngIf="item.offer_price != item.price">₹{{item.price}}</p></li>
                                                </ul>
                                            </div>
                                            <div >
                                                <span data-placement="top" title="Add to Cart" *ngIf="checkStock(item?.stock)==true">
                                                   
                                                    <img src="assets/images/it-cart.png" (click)="callFunction(item.id,1, item.vendor_type,item.variant_id ,item.attribute_value_id_list)">
                                                
                                                </span>
                                                <div data-placement="top" title="Out Stock" *ngIf="checkStock(item?.stock)==false">
                                                           Out Stock
                                                </div>
                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isNodata">
                                <div class="col-lg-12 col-md-12">
                                    <h2 style="text-align: center;font-size:29px;">Coming Soon...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- code by rajan -->
            <div class="col-md-12 mt-5">
                <app-recentproduct></app-recentproduct>
            </div>
            <!-- code by rajan -->
        </div>
    </div>
</section>
<!-- End Category Area -->

