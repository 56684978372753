import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  constructor(private http:HttpClient) { }
  getMenudata(){
    let server_url = environment.base_url;
    var postData = {};
    if (localStorage.getItem("username") === null)
    {
       postData = {
        user_id:0
      }
      
    }
    else
    {
       postData = {
        user_id:localStorage.getItem('user_id')
      }
    }

    return this.http.post(server_url + 'menu-list',postData);
  }
}
