import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { FacebookLoginProvider, GoogleLoginProvider,SocialAuthService,SocialUser } from "angularx-social-login";
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  currentUser:SocialUser|any;
  public setuserData = new BehaviorSubject(new SocialUser); // to assign value
  getUserData = this.setuserData.asObservable(); // to take value
   
  public loggedIn: boolean|any;
  userdata: SocialUser | any;
  // userdata:any;
  user_id:any;
  user_name:any;
  constructor(private http:HttpClient,
    private router: Router,
    private authService: SocialAuthService,
    private common:CommonService,) { }

  loginUser(data:any){
  let url =environment.base_url
    return this.http.post(url+"user-login",data);
  }

  socialLogin(data:any)
  {
    let url =environment.base_url
    return this.http.post(url+"social-register",data);
  }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data=>
      {
          // this.userdata=data
          // console.log("FACEBOOK DATA SHOWING");
          // console.log(data)
          // console.log("FACEBOOK DATA SHOWING");
          let postdata = {
            social_user_id:data.id,
            fname:data.firstName,
            lname:data.lastName,
            email:data.email,
            phone:''
             }
          this.socialLogin(postdata).toPromise().then((cdata:any)=>{
              if(cdata.status == true)
              {
                this.common .showSuccess("",cdata.msg);
                localStorage.setItem('user_id', cdata.data.user_id);
                localStorage.setItem('user_name', cdata.data.fname);
                this.user_id = cdata.data.user_id;
                this.user_name = cdata.data.user_name;
                this.router.navigate(['/'])
                .then(() => {
                  window.location.reload();
                });
              }
              else
              {
                this.common.showError("",cdata.msg);
              }
          });
      })
  }

  signInWithGoogle(): void {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then
      (data=>{
          // this.setuserData.next(data);
          console.log(data);
          let postdata = {
            social_user_id:data.id,
            fname:data.firstName,
            lname:data.lastName,
            email:data.email,
            phone:''
             }
          this.socialLogin(postdata).toPromise().then((cdata:any)=>{
              if(cdata.status == true)
              {
                this.common .showSuccess("",cdata.msg);
                localStorage.setItem('user_id', cdata.data.user_id);
                localStorage.setItem('user_name', cdata.data.fname);
                this.user_id = cdata.data.user_id;
                this.user_name = cdata.data.user_name;
                this.router.navigate(['/'])
                .then(() => {
                  window.location.reload();
                });
              }
              else
              {
                this.common.showError("",cdata.msg);
              }
          });
      })
  }

  signOut(): void {
    this.authService.signOut(true);
  }

}


