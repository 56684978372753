import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http:HttpClient) { }

  saveUserData(data:any){
  let url =environment.base_url

     return this.http.post(url+"register",data);


  }
  saveVendorData(data:any){
      let url =environment.base_url
      console.log(data);
     return this.http.post(url+"vendor-register",data);

  }

  sendOtp(data:any)
  {
    let url =environment.base_url
    return this.http.post(url+"send-otp",data);
  }
  sendForgetOtp(data:any)
  {
    let url =environment.base_url
    return this.http.post(url+"send-forget-otp",data);
  }
  changeUserPassword(data:any){
  let url =environment.base_url

     return this.http.post(url+"change-password",data);


  }

}
