<style type="text/css">
    *{
      font-family: 'Roboto', sans-serif;
      /* background-color:rgb(241 238 229); */
  }
  img{
      width: 100px;
      height: 100px;
  }
  .jumbotron{
      margin-top: 100px;
  }
  i.custom {font-size: 55px; color: rgb(17, 13, 9);
  margin-right: 10px;}
  .lbtn{
background-color:  #ff9800;
color: white;
padding: 13px;
margin-bottom: 20px;
border-radius: 10px;
cursor: pointer;
font-weight: bold;

}
      
  </style>
<div class="container" >
    <div class="jumbotron text-center">
        
        <h1>Your Order Is placed ! </h1>
        <p style="font-size: larger;">{{order_no}}</p>
        <h4>Thank you for your order! Your order is being processed. You will receive an  <br> email confirmation when your order is completed.</h4>
        <br>
        <button class="lbtn" (click)="gotoHome()">CONTINUE SHOPING</button> <br>
        <h4 style="display: inline-flex;">Get answers to all your <p style="color:   #ff9800; margin-left: 5px; margin-right: 5px;" >  Questions </p>you might have.</h4>
        <br>
        <a routerLink="/my-account" class="btn btn-danger">Track Order</a>
    </div>

</div>