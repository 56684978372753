import { Component, OnChanges, OnInit } from '@angular/core';
import { RegisterService } from '../service/register.service';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';

// for google singIN




@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  submitted:boolean = false;
  mobile:any;

  constructor(private registerService:RegisterService,
                 private router:Router,
                 private common:CommonService,

      ) {}
  onSubmit(data:any)
  {
      if(data.password == data.cpassword)
      {
          this.registerService.saveUserData(data).toPromise().then((udata:any)=>{
            if(udata.status == true){
              // alert(udata.msg);
              this.common.showSuccess("",udata.msg);

              this.router.navigate(['/login']);
              localStorage.clear();

            }
            else
            {
              // alert(udata.msg);
              this.common.showError("",udata.msg);
            }
          });
      }
      else
      {
          // alert("Password not match");
          this.common.showError("","Password not match");
      }
  }


  // for google signin




   /*
   responsedata

   {id: "100312189576535418487",
    name: "samar jeet",
    email: "samar7jeet10@gmail.com",
    photoUrl: "https://lh3.googleusercontent.com/a-/AOh14Gh3zKyDFe7bcEGCqFG_4ywQ24BSR2JWOLwTxKh_8w=s96-c",
    firstName: "samar", …}

   */
  // ----
  ngOnInit(): void {
    this.mobile=localStorage.getItem("phone");
  }
}
