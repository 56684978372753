import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {CategoriesService} from '../service/categories.service';
import { CommonService } from '../service/common.service';
import {HomepageService} from '../service/homepage.service';
import {ProductsService} from '../service/products.service';

declare var $: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  categorydata:any=[];
  sliderdata:any=[]
  bannerdata:any=[]
  blogdata:any=[]
  category_product:any=[]
  category_product1:any=[]
  branddata:any=[]
  user_id:any
  homepageproduct:any=[];
  cartdata:any;
  wcount:any;
  Ccount:any;
  constructor(private categoriesService:CategoriesService,private homepageService:HomepageService,private productService:ProductsService,private common:CommonService) { }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
  ngOnInit(){

    this.homepageService.getHomePageProduct().toPromise().then((hdata:any)=>{
      this.homepageproduct=hdata;
      console.log(hdata);
    });

    this.user_id = localStorage.getItem('user_id');
    this.categoriesService.getCategorydata().subscribe(cdata=>{
      this.categorydata=cdata;
    });

    this.homepageService.getSlider().subscribe(sdata=>{
      this.sliderdata=sdata;
    });

    this.homepageService.getBanner().subscribe(sdata=>{
      this.bannerdata=sdata;
    });

    this.homepageService.getBlog().subscribe(bdata=>{
      this.blogdata=bdata;
    });

    this.homepageService.getBrand().subscribe(brdata=>{
      this.branddata=brdata;
    });
    this.homepageService.getHomepageCategoryProduct(40).toPromise().then((catpdata:any)=>{
      this.category_product=catpdata.data;
    });
    this.homepageService.getHomepageCategoryProduct(42).toPromise().then((catpdata1:any)=>{
      this.category_product1=catpdata1.data;
    });

  }
  validateEmail(email:any) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
  onSubscribe(data:NgForm)
  {
    let postdata = data.value;
    if(!this.validateEmail(postdata.email))
    {
        this.common.showError("","Invalid email");
        return;
    }

    if(postdata.email == '')
    {
        this.common.showError("","Email can not be blank");
        return;
    }
    else
    {
      
        $("#subbutton").text('Wait...');
         this.homepageService.saveSubscribe(postdata).toPromise().then((data:any)=>{
          if(data.status == true)
          {
            this.common.showSuccess("",data.msg);
            $("#subbutton").text('Subscribe');
            data.reset();

          }
          else
          {
            this.common.showError("",data.msg);
            $("#subbutton").text('Subscribe');
            data.reset();
          }
        });
    }
  }

  addToCart(id:any){
    if (localStorage.getItem("user_id") === null) {
      // alert("Please login");
      this.common.showError("","Please login")
    }
    else
    {
      this.productService.addToCart(id,'',1,'','','').toPromise().then((data:any)=>{
        // alert(data.msg);
        console.log(data)
        this.productService.cartCountSource.next(data.cart_qty)
        this.common.showSuccess("",data.msg)
        this.Ccount = data.cart_qty;
      });
    }
    // this.common.showSuccess("",data.msg);
  }

  addToWishlist(id:any){
    if (localStorage.getItem("user_id") === null)
    {
      this.common.showError("","Please login")
      // alert("Please login");
    }
    else
    {
        this.productService.addToWishlist(id).toPromise().then((data:any)=>{
        // alert(data.msg);
        this.productService.Wcount.next(data.wcount)
        this.common.showSuccess("",data.msg)
        this.wcount=data.wcount;
      });
    }
  }
}
