import { Component, OnInit } from '@angular/core';
import {PaymentService} from '../service/payment.service';
import {Router} from '@angular/router';
import {UserService} from '../service/user.service';
import { CommonService } from '../service/common.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  amount:any;
  checkoutdata:any;
  paymentdata:any;
  checkoutJson:any;
  paymentJson:any;
  name:any;
  email:any;
  phone:any;
  constructor(private payment:PaymentService,private router:Router,private userService:UserService,private common:CommonService) {

   }
  ngOnInit(): void {
    if(!localStorage.getItem("user_id"))
    {
        this.router.navigate(['/login']);
    }
    if(localStorage.getItem("checkoutdata"))
    {
        this.checkoutdata = localStorage.getItem("checkoutdata");
        this.paymentdata = localStorage.getItem("paymentdata");
        this.checkoutJson = JSON.parse(this.checkoutdata);
        this.paymentJson = JSON.parse(this.paymentdata);
        this.amount = this.paymentJson.grand_total.replace(/\,/g,'');
        this.userService.getUserProfile().toPromise().then((udata:any)=>{
        this.name=udata.data.fname+" "+udata.data.lname;
        this.email=udata.data.email;
        this.phone=udata.data.phone;
        });
    }
    else
    {
        this.router.navigate(['/']);
    }
  }


  pay()
  {
    let options = {
      "key": "rzp_live_3VdKShlzDQj1TB",
      // "key": "rzp_live_zdmMHclKllORIm",
     "amount":  Number(this.amount)*100,
      // "amount": parseInt(this.amount.replace(/\,/g, ''))*100,
      "currency": "INR",
      "name": this.name,
      "description": "Local Korner Payment",
      "image": "https://www.localkorner.com/assets/images/logonew.png",
      "order_id": "",
      "handler": (response:any) => {
// debugger
        let paymentdata = localStorage.getItem("paymentdata");
        let checkdata = localStorage.getItem("checkoutdata");
        let postdata = {
            'product_data': paymentdata,
            'address_id':checkdata,
            'payment_id':response.razorpay_payment_id,
            'user_id':localStorage.getItem("user_id")
        }
        this.payment.saveOrderData(postdata).toPromise().then((pdata:any)=>{
          this.common.showSuccess("",pdata.msg);
          if(pdata.status==true)
          {
            localStorage.removeItem("paymentdata");
            localStorage.removeItem("checkoutdata");
            localStorage.setItem("order_no",pdata.order_no);

            this.router.navigate(['/order-status']).then(() => {
              window.location.reload();
            });
          }
          else
          {
            this.router.navigate(['/order-status']);
          }
        });


    },
      "prefill": {
          "name": this.name,
          "email": this.email,
          "contact": this.phone
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      }
  };
    let rzp1 = new this.payment.nativeWindow.Razorpay(options);
    rzp1.open();
  }

}
