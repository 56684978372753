  <!-- Breadcrumb Area -->
  <section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="#">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Shopping Cart</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Shopping Cart -->
<section class="shopping-cart">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cart-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="t-pro cartpname">Product</th>
                                <th class="t-price cartpname">Price</th>
                                <th class="t-qty cartpname">Quantity</th>
                                <th class="t-total cartpname forleftss">Total</th>
                                <th class="t-rem cartpname"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="showBlank">
                            <tr *ngFor="let cartitem of cartviewdata bordersss">
                                <td class="t-pro d-flex bordersss">
                                    <div class="t-img forhindes">
                                        <a ><img src="{{cartitem.image}}" alt="" style="width: 100px;height: 70px;"></a>
                                    </div>
                                    <div class="t-content">
                                        <p class="t-heading"><a class="cartpname">{{cartitem.product_name}}</a></p>
                                        <ul class="list-unstyled col-sz">

                                            <ng-container *ngIf="cartitem?.variants?.length==0">
                                                <li *ngIf="!cartitem.isgrocerry && cartitem.color"><p class="cartpname">Color : <span class="cartpname">{{cartitem.color}}</span></p></li>
                                                <li *ngIf="cartitem.size"><p class="cartpname">Size : <span class="cartpname">{{cartitem.size}}</span></p></li>
                                            </ng-container>
                                            <ng-container *ngIf="cartitem?.variants?.length>0">
                                               <div *ngFor="let variant of cartitem?.variants">
                                                <li *ngIf="variant?.attribute_value"><p class="cartpname">{{variant?.attribute_name}} : <span class="cartpname">{{variant?.attribute_value}}</span></p></li>
                                            </div>
                                                <!-- <li *ngIf="cartitem?.variants[0]?.attribute_value"><p class="cartpname">{{cartitem?.variants[0]?.attribute_name}} : <span class="cartpname">{{cartitem?.variants[0]?.attribute_value}}</span></p></li>
                                                <li *ngIf="cartitem?.variants[1]?.attribute_value"><p class="cartpname">{{cartitem?.variants[1]?.attribute_name}} : <span class="cartpname">{{cartitem?.variants[1]?.attribute_value}}</span></p></li>
                                            -->
                                            </ng-container>


                                             <td class="t-qty bordersss">
                                                <div class="qty-box forcard">
                                                    <div class="quantity buttons_added">
                                                        <input type="button" value="-" class="minus" (click)="lgbtnclick(cartitem.product_id,qty.value,'m',cartitem?.attribute_value_id_list,cartitem?.variant_id,cartitem?.vendor_type)">
                                                        <input type="number" step="1" min="1" max="10" value="{{cartitem.qty}}" #qty class="qty text" size="4" readonly>
                                                        <input type="button" value="+" class="plus" (click)="lgbtnclick(cartitem.product_id,qty.value,'p', cartitem?.attribute_value_id_list,cartitem?.variant_id,cartitem?.vendor_type)">
                                                    </div>
                                                </div>
                                            </td>
                                        </ul>
                                    </div>
                                </td>
                                <td class="t-price cartpname bordersss">₹{{cartitem.offer_price}}</td>
                                <td class="t-qty bordersss">
                                    <div class="qty-box formobiledevies">
                                        <div class="quantity buttons_added ">
                                            <input type="button" value="-" class="minus" (click)="lgbtnclick(cartitem.product_id,qty.value,'m', cartitem?.attribute_value_id_list,cartitem?.variant_id,cartitem?.vendor_type)">
                                            <input type="number" step="1" min="1" max="10" value="{{cartitem.qty}}" #qty class="qty text" size="4" readonly>
                                            <input type="button" value="+" class="plus" (click)="lgbtnclick(cartitem.product_id,qty.value,'p', cartitem?.attribute_value_id_list,cartitem?.variant_id,cartitem?.vendor_type)">
                                        </div>
                                    </div>
                                </td>
                                <td class="t-total cartpname bordersss">₹{{cartitem.total_price}}</td>
                                <td class="t-rem bordersss"><span (click)="removefromcart(cartitem.id)" class="rem-btn" ><i class="fa fa-trash-o"></i></span></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!showBlank">
                            <tr> 
                                <td colspan="4" class="text-center">No data available</td>
                            </tr>
                     </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="coupon">
                    <h6>Discount Coupon</h6>
                    <p>Enter your coupon code if you have one</p>
                    <form #couponPost="ngForm" (ngSubmit)="onApplyCoupon(couponPost.value)">
                        <input type="text" name="coupon_code" placeholder="Your Coupon" ngModel="{{coupon_code}}">
                        <input type="hidden" name="sub_total" ngModel="{{cartcalcdata.subtotal}}">
                        <button type="submit" style="margin-right: 5px;" *ngIf="showapply">Apply Code</button>
                        <button type="button" (click)="removeCoupon();" *ngIf="showremove">Remove Coupon</button>
                    </form>
                </div>
            </div>
            <div class="col-md-6" *ngIf="showBlank">
                <div class="crt-sumry">
                    <h5>Cart Summery</h5>
                    <ul class="list-unstyled">
                        <li>Subtotal <span>₹{{cartcalcdata.subtotal}}</span></li>
                        <li>Coupon Discount <span>₹{{cartcalcdata.coupon_discount}}</span></li>
                        <li>Shipping Charge<span>₹{{cartcalcdata.shipping_charge}}</span></li>
                        <li>Grand Total <span>₹{{cartcalcdata.grand_total}}</span></li>
                    </ul>
                    <div class="cart-btns text-right">
                        <button type="button" class="up-cart" routerLink="/checkout">Checkout</button>
                        <!-- <a type="button" class="chq-out" routerLink="/checkout">Checkout</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Shopping Cart -->
