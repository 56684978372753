<section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-3">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="#">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Contact -->
<section class="contact-area" style="margin: 0; padding: 0;">
    <div class="container contactform">
        <div class="row">
            <div class="col-lg-4 col-md-5">
                <div class="contact-box-tp">
                    <h4>Contact Info</h4>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="contact-box d-flex">
                            <div class="contact-icon">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <div class="contact-content">
                                <h6>Our Location</h6>
                                <p>{{address}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="contact-box d-flex">
                            <div class="contact-icon">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div class="contact-content">
                                <h6>Email Address</h6>
                                <p>{{email}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="contact-box d-flex">
                            <div class="contact-icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div class="contact-content">
                                <h6>Phone Number</h6>
                                <p>{{phone}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="social-link">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="{{facebook_link}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="{{twitter_link}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="{{linkdin_link}}" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                        <li class="list-inline-item"><a href="https://www.instagram.com/localkornerr/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-7">
                <div class="contact-form">
                    <h4>Get In Touch</h4>
                    <form #contactPost="ngForm" (ngSubmit)="onSubmit(contactPost)">
                        <div class="row">
                            <div class="col-md-6">
                                <p><input type="text" id="name" name="name" placeholder="Full Name" required="" ngModel></p>
                             </div>
                            <div class="col-md-6">
                                <p><input type="text" id="email" name="email" placeholder="Email" required="" ngModel></p>
                            </div>
                            <div class="col-md-12">
                                <p><input type="text" id="subject" name="subject" placeholder="Subject" ngModel></p>
                            </div>
                            <div class="col-md-12">
                                <p><textarea name="message" id="message" placeholder="Message" required="" ngModel></textarea></p>
                            </div>
                            <div class="col-md-12 text-right">
                                <button type="submit">Send Message</button>
                            </div>
                        </div>
                        <div id="form-messages"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid forcontactmg " id="map">
        <div class="contact-box-tp text-center">
           <h4 >OUR LOCATION</h4>
        </div>
        <iframe class="mppaddiing" width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Local%20Korner,%206-D,%20Gaurav%20Vihar%20Colony,%20Chinhat,%20Lucknow,%20Uttar%20Pradesh%20226028+(Local%20Korner)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </div>
</section>
<!-- End Contact -->

<!-- Brand area 2 -->
<section class="brand2">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="tp-bnd owl-carousel">
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-01.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-02.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-03.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-04.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-05.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-06.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a href="#"><img src="assets/images/brand-07.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

