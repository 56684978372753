<!-- Slider Area -->
<div class="slider-area">

    <div class="col-lg-12 col-md-12 col-sm-0 col-xs-0 d-lg-block m-0 " style="padding-left: 0px !important;">
        <div class="menu-widget mobilecategorylist">
            <p><i class="fa fa-th-large" aria-hidden="true"></i> All Categories</p>
            <ul class="list-unstyled">
                <li *ngFor="let item of categorydata.data">
                    <a href="product-list/by-category/{{item.id}}"><img src="" alt="">{{item.category}}<i class="fa fa-angle-right" *ngIf="item.issubcategory"></i></a>
                    <div class="mega-menu" *ngIf="item.issubcategory">
                        <div class="menu-widget" *ngFor="let subcategory of item.subcategory" class="list-unstyled">
                            <a href="product-list/by-subcategory/{{subcategory.id}}">
                                <h6>{{subcategory.subcategory}}</h6>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 padding-fix-l20">
                <div class="owl-carousel owl-slider">
                    <div *ngFor="let sl of sliderdata.data" class="slider-item slider-item1">
                        <a href="{{sl.url}}"><img src="{{sl.slider_image}}" alt="" class="img1 wow fadeInRight effect" data-wow-duration="1s" data-wow-delay="0s"></a>
                    </div>
                </div>

            </div> -->

</div>
<!-- End Slider Area -->