import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

function _window() : any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  get nativeWindow() : any {
    return _window();
 }
  constructor(private http:HttpClient) { }

  saveOrderData(postdata:any)
  {
    let server_url = environment.base_url;

    return this.http.post(server_url+'save-order',postdata);
  }

  saveChangeAddress(postdata:any)
  {
    let server_url = environment.base_url;
    return this.http.post(server_url+'select-user-address',postdata);
  }
}
