        <!-- Breadcrumb Area -->
        <section class="breadcrumb-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="breadcrumb-box text-center">
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item"><a href="#">Home</a></li>
                                <li class="list-inline-item"><span>||</span> Blog Deatails</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Breadcrumb Area -->

        <!-- Blog Details -->
        <section class="blog-details">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-md-8">
                        <div class="blog-d-box">
                            <div class="image-content">
                                <h3>{{title}}</h3>
                            </div>
                            <div class="image-content">
                                <p>{{short_description}}</p>
                            </div>
                            <div class="image-box">
                                <img src="{{image}}" alt="" class="img-fluid" style="width: 100%;">
                            </div>

                            <div class="image-content">
                                <p>{{description}}</p>
                            </div>

                            <div class="blog-comment">
                                <h4>Comments</h4>
                                <div class="comment-box d-flex" *ngFor="let bc of blog_comment">
                                    <div class="comment-img">
                                        <a href="#"><img src="assets/images/user_default.png" alt="" style="width: 50px;"></a>
                                    </div>
                                    <div class="comment-con">
                                        <ul class="list-unstyled list-inline">
                                            <li class="list-inline-item"><a href="#">{{bc.name}}</a></li>
                                        </ul>
                                        <p>{{bc.comment}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-form">
                                <h5>Leave Your Comment</h5>
                                <form #commentPost="ngForm" (ngSubmit)="onSubmit(commentPost)">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="text" name="name" placeholder="Name" required="" value="" ngModel>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="text" name="email" placeholder="Email" required="" value="" ngModel>
                                        </div>
                                        <div class="col-md-12">
                                            <textarea placeholder="comment" name="comment" required="" ngModel></textarea>
                                            <button type="submit">Post Comment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="blog-po">
                                    <div class="sec-title">
                                        <h6>Recent Post</h6>
                                    </div>
                                    <div class="post-box">
                                        
                                        <div class="rec-post d-flex" *ngFor="let rc of recent">
                                            <div>
                                                <a (click)="gotoblogdetail(rc.id)" style="cursor: pointer;"><img src="{{rc.image}}" alt=""></a>
                                            </div>
                                            <div>
                                                <p><a (click)="gotoblogdetail(rc.id)" style="cursor: pointer;">{{rc.title}}</a></p>
                                                <span>{{rc.created_at}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="blog-banner">
                                    <a href="#"><img src="images/s-banner2.jpg" alt="" class="img-fluid"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog Details -->
