import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private http:HttpClient) { }
  getFooterData()
  {
    let  server_url = environment.base_url;
    return this.http.get(server_url + 'get-footer-data');
  }
}
