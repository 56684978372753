<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a routerLink="home">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Register</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Register -->
<section class="register">
    <div class="container">




        <div class="row">
            <div class="col-md-12">

                <form  #signupPost="ngForm" (ngSubmit)="onSubmit(signupPost.value)">
                    <h5>Change Your Password</h5>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Password*</label>
                            <input type="text" class="form-control"  placeholder="Your password" name="password" ngModel>
                        </div>
                        <input type="hidden" name="phone" ngModel="{{mobile}}">
                        <div class="form-group col-md-6">
                            <label>Confirm Password*</label>
                            <input type="password" class="form-control" placeholder="Confirm password" name="cpassword" ngModel>
                        </div>
                        <div class="col-md-12 text-left">
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Register -->
