import { Component, OnInit } from '@angular/core';
import {CartService} from '../service/cart.service';
import { CommonService } from '../service/common.service';
import {ProductsService} from '../service/products.service';

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.css']
})
export class ShoppingcartComponent implements OnInit {
  cartviewdata:any;
  qty:any;
  coupon_code:any;
  showBlank:any;
  cartcalcdata:any;
  showremove:any;
  showapply:any;
  constructor(private cartService:CartService,
               private productsService:ProductsService,
               private common:CommonService) { }

  ngOnInit(): void {
    localStorage.setItem('coupon_code','');
    this.cartViewData();
  }
  cartViewData()
  {
    let clength=0;
    this.cartService.getCartViewdata().toPromise().then((cdata:any)=>{
      this.cartviewdata=cdata.data;
      this.cartcalcdata=cdata;
      this.coupon_code = localStorage.getItem('coupon_code');
      clength = Object.keys(cdata.data).length;

      this.productsService.cartCountSource.next(clength);
      if (localStorage.getItem("user_id") === null || clength == 0)
      {
          this.showBlank = false;
      }
      else
      {
        this.showBlank = true;
      }
      if (localStorage.getItem("coupon_code") == '')
      {
          this.showremove = false;
          this.showapply = true;

      }
      else
      {
        this.showremove = true;
          this.showapply = false;

      }
    });
  }
  lgbtnclick(pid:any,qty:any,type:any,attribute_value_id_list:string,variant_id:string,vendor_type:string) {


    if(type=='m' && qty != 1)
    {
      qty=parseInt(qty)-1;
    }
    else if(type=='m' && qty == 1)
    {
      qty=parseInt(qty);
    }
    else{
      qty=parseInt(qty)+1;
    }

    this.productsService.addToCart(pid,vendor_type,qty,'',variant_id,attribute_value_id_list).toPromise().then((data:any)=>{
      // alert(data.msg);
      this.common.showSuccess("",data.msg);
      this.cartViewData();
    });
  }

  onApplyCoupon(postData:any)
  {
    postData.user_id = localStorage.getItem("user_id");
    if(postData.coupon_code == '')
    {
      this.common.showError("","Coupon code can not be blank");
    }
    this.productsService.applyCouponCode(postData).toPromise().then((cpdata:any)=>{
      if(cpdata.status == true)
      {
          localStorage.setItem('coupon_code',cpdata.coupon_code);
          this.cartViewData();
          this.common.showSuccess("",cpdata.msg);
      }
      else
      {
        this.common.showError("",cpdata.msg);
        localStorage.setItem('coupon_code','');
      }

    });
  }
  removefromcart(cartid:any)
  {
    this.productsService.removefromcart(cartid).toPromise().then((rdata:any)=>{
      if(rdata.status==true)
      {
        this.common.showSuccess("",rdata.msg)
        this.cartViewData();
      }
      else
      {
        this.common.showError("",rdata.msg)
      }
    });
  }
  removeCoupon()
  {
    localStorage.setItem('coupon_code','');
    this.cartViewData();
  }
}
