        <!-- Breadcrumb Area -->
        <section class="breadcrumb-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="breadcrumb-box text-center">
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item"><a href="#">Home</a></li>
                                <li class="list-inline-item"><span>||</span> Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Breadcrumb Area -->

        <!-- Blog Full -->
        <section class="blog1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let blg of blogdata">
                        <div class="blog-box">
                            <div class="blog-img">
                                <a routerLink="blog-details/{{blg.id}}"><img src="{{blg.image}}" alt="" class="img-fluid"></a>
                            </div>
                            <div class="blog-content">
                                <h5><a routerLink="blog-details/{{blg.id}}">{{blg.title}}</a></h5>
                                <ul class="list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fa fa-user-o"></i><a href="#">Admin</a></li>
                                    <li class="list-inline-item"><i class="fa fa-calendar"></i>{{blg.created_at}}</li>
                                </ul>
                                <p>{{blg.short_description}}</p>
                            </div>
                        </div>
        		        	</div>
                </div>
            </div>
        </section>
        <!-- End Blog Full -->
