import { Component, OnInit,Input,OnChanges, SimpleChange } from '@angular/core';
import {ProductsService} from '../service/products.service';
import {MenusService} from '../service/menus.service';
import { Router } from '@angular/router';
import {CategoriesService} from '../service/categories.service';
import { CommonService } from '../service/common.service';
// import

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit  {
  @Input() menuData:any
  cartdetail:any
  user_id:any
  user_name:any;
  isLoggedIn=false;
  wcount=0;
  nwcount:number=0;
  nCcount:number=0;
  mcategorydata:any;
  categorydata:any;

  constructor(private productService:ProductsService,
             private menusService:MenusService,
             private router:Router,
             private common:CommonService,
           
             private categoriesService:CategoriesService) { }

  ngOnInit(): void {
      this.productService.getCartCount().toPromise().then((data:any)=>{
      this.nCcount = data.cart_count;
      });
      
      this.productService.cartCount.subscribe((number)=>{this.nCcount = number})


      this.productService.getWishlistCount().toPromise().then((data:any)=>{
        this.nwcount = data.wishlist_count;
      });


      this.productService.sharedWcount.subscribe((number)=>{this.nwcount = number})
      this.user_id = localStorage.getItem('user_id');
      this.user_name = localStorage.getItem('user_name');
      if(localStorage.getItem('user_name'))
      {
        this.isLoggedIn = true;
      }
      else
      {
        this.isLoggedIn = false;
      }

      this.categoriesService.getCategorydata().toPromise().then((ddata:any)=>{
        this.mcategorydata=ddata.data;
        this.categorydata=ddata.data;
      });
      

  }


  onSearch(data: any)
  {
    let search_key = data.search_key;
    if(search_key == '')
    {
        this.common.showError("","Search key can not be blank.");
    }
    else
    {
      this.router.navigate(['/product-list/search/',search_key])
      .then(() => {
        window.location.reload();
      });
    }
    
  }
  onSearchss(event: any){

    let search_key=event.target.value;
   

    this.router.navigate(['/product-list/search/',search_key])
      .then(() => {
         alert(search_key);
      });
 
 }
  
  getsearchlist(data: any)
  {


    let search_key = data.search_keys;
    alert(search_key);
    if(search_key == '')
    {
        this.common.showError("","Search key can not be blank.");
    }
    else
    {
      this.router.navigate(['/product-list/search/',search_key])
      .then(() => {
        window.location.reload();
      });
    }
    
  }

  logout()
  {
    localStorage.clear();
    window.location.reload();
  }
  gotoHome()
  {
    this.router.navigate(['/'])
        .then(() => {
          window.location.reload();
        });
  }

  redirectProd(suId:number)
  {
    this.router.navigate(["product-list/by-subcategory/"+Number(suId)]);

  }
   
   
  
  //     // item=
  // keyword = 'name';

  // data = [
  //   {id: 1,name: 'Aashirvaad'},
  //   {id: 2,name: 'Himalaya'},
  //   {id: 3,name: 'Garnier'},
  //   {id: 4,name: 'Amul'},
  //   {id: 5,name: 'Tata'},
  //   {id: 6,name: 'Gowardhan'},
  //   {id: 7,name: 'Fortune'},
  //   {id: 8,name: 'Anik'},
  //   {id: 9,name: 'Dalda'},
  //   {id: 10,name: 'Kitchen'},
  // ];
  //   selectEvent(item:any) {
  //     console.log(item);
  //     let search_key = item.name;
  //       if(search_key == '')
  //       {
  //           this.common.showError("","Search key can not be blank.");
  //       }
  //       else
  //       {
  //         this.router.navigate(['/product-list/search/',search_key])
  //         .then(() => {
  //           window.location.reload();
  //         });
  //       }
    
  //   }
  
  //   onChangeSearch(val: string) {
  //     console.log(val);
      
  //     // fetch remote data from here
  //     // And reassign the 'data' which is binded to 'data' property.
  //   }
    
  //   onFocused(e:any){
  //     // do something when input is focused
  //   }
  


}