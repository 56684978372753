import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { RegisterService } from '../service/register.service';
declare var $: any;

@Component({
  selector: 'app-vender-register',
  templateUrl: './vender-register.component.html',
  styleUrls: ['./vender-register.component.css']
})
export class VenderRegisterComponent implements OnInit {
  submitted:boolean = false;
  mobile:any;
  constructor(private registerService:RegisterService,
              private router:Router,
              private common:CommonService) {
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
  onSubmit(postdata: NgForm)
  {
    let data =  postdata.value;
    let isvalidate = true;
    if(data.vendor_name == '')
    {
      this.common.showError("",'Name can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.email == '')
    {
      this.common.showError("",'Email can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.mobile == '')
    {
      this.common.showError("",'Mobile can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.state == '')
    {
      this.common.showError("",'State can not be blank');
    }
     else
    {
      isvalidate = true;
    }
    if(data.address == '')
    {
      this.common.showError("",'Address can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.city == '')
    {
      this.common.showError("",'City can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.zip == '')
    {
      this.common.showError("",'Zip can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.password == '')
    {
      this.common.showError("",'Password can not be blank');
    }
    else
    {
      isvalidate = true;
    }
    if(data.cpassword == '')
    {
      this.common.showError("",'Confirm Password can not be blank');
    }
    else
    {
      isvalidate = true;
    }
        if(isvalidate == true)
        {
          let pData = 
          {
            vendor_name:data.vendor_name,
            gst_no:data.gst_no,
            email:data.email,
            latitude:$("#user_lat").val(),
            longitude:$("#user_long").val(),
            mobile:data.mobile,
            state:data.state,
            address:data.address,
            city:data.city,
            zip:data.zip,
            password:data.password,
            service_description:data.service_description,
            alternate_mobile:data.alternate_mobile,
            company_name:data.company_name
          }
          if(data.password == data.cpassword)
          {
              this.registerService.saveVendorData(pData).toPromise().then((udata:any)=>{
                if(udata.status == true){
                  this.common.showSuccess("",udata.msg);
                  postdata.reset();
                  
                }
                else
                {
                  this.common.showError("",udata.msg);
                }
              });
          }
        else
        {
            this.common.showError("","Password not match");
        }
      }
      else
      {
          this.common.showError("","Something went wrong");
      }
  }

  ngOnInit(): void {
    this.mobile=localStorage.getItem("phone");
  }



}
