<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12 topss">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">Home <span>||</span></li>
                        <li class="list-inline-item"><a href="#">Refund Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->
<!-- About Area -->
<section class="about-us">
    <div class="container">
        <div class="row m-2">
            <div class="col-md-12">
                <b>Returns</b>
            </div>
            <div class="col-md-12">
                <p>Our policy lasts 10 days. If 10 days have gone by since your purchase, unfortunately, we can’t offer you a refund or exchange.</p>
            <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
            <p>Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers, or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.</p>
            <p>Additional non-returnable items:</p>
            <ul>
                <li>	Gift cards</li>
                <li>	Health and Personal care items</li>
            </ul>
            <p>To complete your return, we require a receipt or proof of purchase.</p>
            <p>Please do not send your purchase back to the manufacturer.</p>
            <p>There are certain situations where only partial refunds are granted: (if applicable)
                Book with obvious signs of use
                Any item not in its original condition is damaged or missing parts for reasons not due to our error.
                Any item that is returned more than 10 days after delivery
            </p>
        </div>
        <div class="col-md-12">
            <b>Refunds (if applicable)</b>
            <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
                If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days. 
            </p>
        </div>
        <div class="col-md-12">
            <b>Late or missing refunds (if applicable)</b>
            <p>
                If you haven’t received a refund yet, first check your bank account again.
Then contact your credit card company, it may take some time before your refund is officially posted.
Next, contact your bank. There is often some processing time before a refund is posted.
If you’ve done all of this and you still have not received your refund yet, please contact us at 
[info@localkorner.com].
            </p>
        </div>
        <div class="col-md-12">
            <b>Sale items (if applicable)</b>
            <p>Only regular priced items may be refunded, unfortunately, sale items cannot be refunded.</p>
        </div>
        <div class="col-md-12">
            <b>Exchanges (if applicable)</b>
            <p>We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at <b>[info@localkorner.com]</b> and our executive will collect it from your doorstep. </p>
        </div>
        <div class="col-md-12">
            <b>Gifts</b>
            <p>If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.</p>
        
            <p>If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.</p></div>
        <div class="col-md-12">
            <b>Shipping</b>
            <p>To return your product, you should mail the product to: 624 M, New- 024, Yamuna Vihar Colony, Chinhat Zone-4,Lucknow, Uttar Pradesh, 226028.</p>
        <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
    <p>Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>    
    <p>If you are shipping an item over Rs 2500/- you should consider using a trackable shipping service or purchasing shipping insurance. Wedon’t guarantee that we will receive your returned item.</p>
    </div>
        </div>
    </div>
</section>
<!-- End About Area -->
