import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
// import { commonallcategoryComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HttpClientModule } from '@angular/common/http';
import { MenusService } from './service/menus.service';
import { CategoriesService } from './service/categories.service';
import { RegisterService } from './service/register.service';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { VerifyotpComponent } from './verifyotp/verifyotp.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
import { PaymentComponent } from './payment/payment.component';
import { TermconditionComponent } from './termcondition/termcondition.component';
import { ShippingpolicyComponent } from './shippingpolicy/shippingpolicy.component';
import { PaymentstatusComponent } from './paymentstatus/paymentstatus.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { BlogComponent } from './blog/blog.component';
import { BlogdetailComponent } from './blogdetail/blogdetail.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { VenderRegisterComponent } from './vender-register/vender-register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { CommonallcategoryComponent } from './commonallcategory/commonallcategory.component';
// import { CommonallcategoryComponent } from './commonallcategory/commonallcategory.component';
import { RecentproductComponent } from './recentproduct/recentproduct.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
import { AllSellersListComponent } from './all-sellers-list/all-sellers-list.component';
// import { SearchingbarComponent } from './searchingbar/searchingbar.component';
// import { MatSliderModule } from '@angular/material/slider';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactusComponent,
    HomepageComponent,
    AboutusComponent,
    LoginComponent,
    RegisterComponent,
    CheckoutComponent,
    MyaccountComponent,
    WishlistComponent,
    VerifyotpComponent,
    ProductListComponent,
    ProductDetailsComponent,
    ShoppingcartComponent,
    PaymentComponent,
    TermconditionComponent,
    ShippingpolicyComponent,
    PaymentstatusComponent,
    UserprofileComponent,
    BlogComponent,
    BlogdetailComponent,
    PrivacypolicyComponent,
    VenderRegisterComponent,
    CommonallcategoryComponent,
    RecentproductComponent,
    ForgetpasswordComponent,
    ChangepasswordComponent,
    RefundpolicyComponent,
    AllSellersListComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SocialLoginModule,
    //for rajan
    AutocompleteLibModule,
    // for tostr samarjeet
    BrowserAnimationsModule,
    ToastrModule.forRoot()
    // MatSliderModule
  ],
  providers: [
    MenusService,
    CategoriesService,
    RegisterService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '644893614313-eo9kpnfdre6pimc8ujpqlkicirtjjipp.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("595807998187312")
          },
        ]
      } as SocialAuthServiceConfig,
    }
  ] ,
  bootstrap: [AppComponent]
})
export class AppModule { }
