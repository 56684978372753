import { Component, OnChanges, OnInit } from '@angular/core';
import { RegisterService } from '../service/register.service';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

    submitted:boolean = false;
  mobile:any;

  constructor(private registerService:RegisterService,
                 private router:Router,
                 private common:CommonService,

      ) {}

  ngOnInit(): void {
  }
  onSubmit(data:any)
  {
      if(data.password == data.cpassword)
      {
      	  	let postdata = {
		      phone:localStorage.getItem("phone"),
		      password:data.password,
		    }
          this.registerService.changeUserPassword(postdata).toPromise().then((udata:any)=>{
            if(udata.status == true){
              // alert(udata.msg);
              this.common.showSuccess("",udata.msg);

              this.router.navigate(['/login']);
              localStorage.clear();

            }
            else
            {
              // alert(udata.msg);
              this.common.showError("",udata.msg);
            }
          });
      }
      else
      {
          // alert("Password not match");
          this.common.showError("","Password not match");
      }
  }

}
