import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../service/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit {
  constructor(
    private productService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private common: CommonService
  ) {}
  productdata: any = [];
  cartdata: any;
  tempArr: any = { brands: [] };
  order_by_filter = '';
  categoryId = '';
  wcount = 0;
  isNodata: any;
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        let order_by = 'new';
        this.order_by_filter = order_by;
        this.getProductList(order_by);
      }
    });
  }

  ngOnChanges() {
    console.log('change data.');
  }

  getProductList(order_by: any) {
    let barnd_filter = this.tempArr.brands;

    this.order_by_filter = order_by;

    var params = this.route.snapshot.url[1].path;
    let plength = 0;
    if (params == 'best-seller') {
      this.productService
        .getBestSeller(order_by, barnd_filter, this.categoryId)
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    } else if (params == 'todays-deal') {
      this.productService
        .getTodayDeal(order_by, barnd_filter, this.categoryId)
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    } else if (params == 'fashion') {
      this.productService
        .getFashionProduct(order_by, barnd_filter, this.categoryId)
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    } else if (params == 'by-subcategory') {
      var subcategoryid = this.route.snapshot.url[2].path;
      this.productService
        .getProductBySubcategory(
          subcategoryid,
          order_by,
          barnd_filter,
          this.categoryId
        )
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    } else if (params == 'by-category') {
      var catid = this.route.snapshot.url[2].path;
      this.productService
        .getProductByCategory(catid, order_by, barnd_filter, this.categoryId)
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    } else if (params == 'search') {
      var searchkey = this.route.snapshot.url[2].path;
      this.productService
        .getProductBySearch(searchkey, order_by, barnd_filter, this.categoryId)
        .toPromise()
        .then((cdata: any) => {
          this.productdata = cdata;
          plength = Object.keys(cdata.data).length;
          if (plength == 0) {
            this.isNodata = false;
          } else {
            this.isNodata = true;
          }
        });
    }
  }

  checkStock(stock: string) {
    return Number(stock) ? true : false;
  }

  callFunction(
    id: any,
    qty: any,
    vendor_type: string,
    variant_id: string,
    attribute_value_id_list: string
  ) {
    if (localStorage.getItem('user_id') === null) {
      // alert("Please login");
      this.common.showError('', 'Please login');
    } else {
      this.productService
        .addToCart(
          id,
          vendor_type,
          qty,
          '',
          variant_id,
          attribute_value_id_list
        )
        .toPromise()
        .then((data: any) => {
          // alert(data.msg);
          this.productService.cartCountSource.next(data.cart_qty);
          this.common.showSuccess('', data.msg);
          this.cartdata = data.status;
        });
    }
  }

  onCheckboxChange(e: any, val: any) {
    if (e.target.checked) {
      this.tempArr.brands.push(val);
    } else {
      let index = this.tempArr.brands.indexOf(val);
      this.tempArr.brands.splice(index, 1);
    }

    this.getProductList(this.order_by_filter);
  }

  onSelectCategory(catId: any) {
    this.categoryId = catId;
    this.getProductList(this.order_by_filter);
  }

  addToWishlist(id: any) {
    if (localStorage.getItem('user_id') === null) {
      this.common.showError('', 'Please Login');
    } else {
      this.productService
        .addToWishlist(id)
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
          this.productService.Wcount.next(data.wcount);
          // this.productService.Wcount.next(data.wcount)
          this.wcount = data.wcount;
        });
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
