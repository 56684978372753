<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a routerLink="/home">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Login</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" type="text/css">
<script src="https://apis.google.com/js/api:client.js"></script>

<!-- Log In -->
<section class="login">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="n-customer">
                    <h5>New Customer</h5>
                    <p>Join with us to get the best deals and products.</p>
                    <a routerLink="/verifyotp">Create an Account</a>
                </div>
                <div class="row">

                    <div class="col-lg-6 gSignInWrapper mt-5" (click)="googleLogin()" >
                      <span class="glabel"></span>
                      <div class="gcustomBtn gcustomGPlusSignIn">
                        <span class="gicon"></span>
                        <span class="gbuttonText p-2">Log in with Google</span>
                      </div>
                    </div>
        
                    <!-- <div class="col-lg-6 fb-login-button mt-5" data-width="" data-size="large"   data-button-type="login_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="false" (click)="facebookLogin()">
                    </div> -->
                    <div class="col-md-6 mt-5">
                       <span style="cursor: pointer;"><img src="assets/images/facebook.png" alt="" (click)="facebookLogin()"></span>
                    </div>
                  </div>
            </div>
            <div class="col-md-6">
                <div class="r-customer">
                    <h5>Registered Customer</h5>
                    <p>If you have an account with us, please log in.</p>
                    <form #loginPost="ngForm" (ngSubmit)="onSubmit(loginPost.value)">
                        <div class="emal">
                            <label>Mobile or Email Address</label>
                            <input type="text" name="auth_id" required="" ngModel>
                        </div>
                        <div class="pass">
                            <label>Password</label>
                            <input type="password" name="password" required="" ngModel>
                        </div>
                        <div class="d-flex justify-content-between nam-btm">
                            <div>
                                Lost your password ? <a routerLink="/forget-password"> Click Here</a>
                            </div>
                        </div>
                        <button type="submit" name="button">Log In</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Log In -->

<!-- Brand area 2 -->
<!--<section class="brand2">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="tp-bnd owl-carousel">
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-01.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-02.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-03.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-04.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-05.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-06.png" alt="" class="img-fluid"></a>
                    </div>
                    <div class="bnd-items">
                        <a routerLink="#"><img src="assets/images/brand-07.png" alt="" class="img-fluid"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Brand area 2 -->
