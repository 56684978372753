 <!-- Breadcrumb Area -->
 <section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-box text-center">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a routerLink="home">Home</a></li>
                        <li class="list-inline-item"><span>||</span> Checkout</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Checkout -->
<section class="checkout">
    <div class="container">
    <form #checkoutPost="ngForm" (ngSubmit)="onSubmit(checkoutPost.value)">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-lg-4 col-xs-4 col-4">
                <button type="button" class="btn float-left btn-add btn-new " data-toggle="modal"
                data-target=".bd-example-modal-lg" >ADD
                NEW ADDRESS</button>
            
            </div>

            <div class="col-md-8 col-sm-8 col-lg-8 col-xs-8 col-8 text-right">
               <span class="Shipping-msg" *ngIf="cartviewdata?.deliveryMsg"> {{cartviewdata?.deliveryMsg}}</span>
            </div>
        </div>
            <div class="row">
            <div class="col-md-7" *ngIf="useraddress?.data!=undefined">
                <div class="card" *ngFor="let ud of useraddress?.data" style="padding-bottom: 0!important;">
                    <div class="card-body" style="padding-bottom: 0!important;">
                        <input type="radio" name="address" checked value="{{ud.id}}" (change)="changeAddress(ud.id)" >
                        <span><p>{{ud.fname}} {{ud.lname}} <span class="label label-default">{{ud.ad_type}}</span></p></span>
                        <span>{{ud.phone}} ,</span>
                        <span>{{ud.address}} ,</span>
                        <span>{{ud.city}} ,</span>
                        <span>{{ud.zip}} ,</span>
                        <span><p>{{ud.email}} </p></span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
            <div class="col-md-12">
                <div class="coupon">
                    <p>Enter your coupon code if you have one</p>
                    <form #couponPost="ngForm" (ngSubmit)="onApplyCoupon(couponPost.value)">
                        <input type="text" name="coupon_code" placeholder="Your Coupon" ngModel="{{coupon_code}}">
                        <input type="hidden" name="sub_total" ngModel="{{cartviewdata.subtotal}}">
                        <button type="submit" class="coupon-btn" style="margin-right: 5px;" *ngIf="showapply">Apply Code</button>
                        <button type="button" class="coupon-btn" (click)="removeCoupon();" *ngIf="showremove">Remove Coupon</button>
                    </form>
                </div>
            </div>
                    <div class="col-md-12" style="padding-bottom: 0!important;">
                        <div class="order-review">
                            <h5>Order Review</h5>
                            <div class="review-box">
                                <ul class="list-unstyled">
                                    <li>Product <span>Total</span></li>
                                    <li class="d-flex justify-content-between" *ngFor="let cartitem of cartviewdata.data">
                                        <div class="pro">
                                            <img src="{{cartitem.image}}" alt="" style="width: 60px;height: 60px;"> 
                                            <p>{{cartitem.product_name}}</p>
                                            <span>{{cartitem.qty}} X ₹{{cartitem.offer_price}}</span>
                                        </div>
                                        <div class="prc">
                                            <p>₹{{cartitem.total_price}}</p>
                                        </div>
                                    </li>
                                    
                                    <li>Sub Total <span>₹{{cartviewdata.subtotal}}</span></li>
                                    <li>Coupon Discount <span>₹{{cartviewdata.coupon_discount}}</span></li>
                                    <li>Shipping Charge <span>₹{{cartviewdata.shipping_charge}}</span></li>
                                    <li>Grand Total <span>₹{{cartviewdata.grand_total}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="padding-bottom: 0!important;"><h5 style="margin-bottom: 12px!important;">Choose delivery slot</h5></div>
                    <div class="col-md-4">
                        <select name="slot_day" id="slot_day" class="form-control" (change)="getSlot();">
                            <option value="">Day</option>
                            <option *ngFor="let sd of slotdate">{{sd}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <select name="slot_time" id="slot_time" class="form-control">
                            <option value="">Slot</option>
                            <option *ngFor="let st of slot_time" [disabled]="st.disabled">{{st.slot}} {{st.slot_full}}</option>
                        </select>
                    </div>
                    <div class="col-md-12">
                        <div class="pay-meth">
                            <h5>Payment Method</h5>
                            <div class="pay-box">
                                <ul class="list-unstyled">
                                    <li>
                                        <input type="radio" id="pay1" name="payment"  [checked]="true" value="cod" ngModel="cod">
                                        <label for="pay1"><span><i class="fa fa-circle"></i></span>Pay On Delivery</label>
                                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque sint placeat illo animi quis minus accusantium ad doloribus, odit explicabo asperiores quidem.</p> -->
                                    </li>
                                    <li>
                                        <input type="radio" id="pay2" name="payment" ngModel="online" value="online">
                                        <label for="pay2"><span><i class="fa fa-circle"></i></span>Online</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="submit" name="button" class="ord-btn">Place Order</button>
                    </div>
                </div>
            </div>
            </div>
       
    </form>
    </div>
</section>
<div id="addressmodal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="card-body">
                <div class="card w-100">

                    <div class="details p-3">
                        <div class="card-body bg-light">
                            <h2 class="card-title">Add new shipping address</h2>
                        </div>
                        <form #addressPost="ngForm"  (ngSubmit)="addNewAddress(addressPost)">
                            <div class="row">
                                <div class="col-lg-6 mt-3">
                                    <input type="text" name="fname"
                                        placeholder="First Name" ngModel required>
                                </div>
                                <input type="hidden" ngModel="{{user_id}}" name="ship_user_id">
                                <div class="col-lg-6 mt-3">
                                    <input type="text" name="lname"
                                        placeholder="Last Name" ngModel required>
                                </div>
                                <div class="col-lg-6 mt-3">
                                    <input type="text" name="email"
                                        placeholder="Email" ngModel>
                                </div>
                                <div class="col-lg-6 mt-3">
                                    <input type="text" name="phone"
                                        placeholder="Phone" ngModel required>
                                </div>
                                <div class="col-lg-12 mt-3">
                                    <input type="text" name="address"
                                        placeholder="Address" id="address" ngModel required onblur="getLatLong();">
                                </div>
                                <div class="col-lg-6 mt-3">

                                    <select name="state" class="form-control" id="State" ngModel="0" (ngModelChange)="changeState($event)"   placeholder="State" required>
                                        <option [value]="0" selected>Select State</option>
                                        <option *ngFor="let state of stateList" [value]="state.id" >{{state.name}}</option>
                                    </select>
                                    <!-- <input type="text" name="state"
                                        placeholder="State" ngModel required> -->
                                </div>
                                <div class="col-lg-6 mt-3">

                                    <select name="city" class="form-control" id="city"  placeholder="city" ngModel="0" required>
                                        <option [value]="0" selected>Select City</option>
                                        <option *ngFor="let city of cityList" [value]="city.id" >{{city.city}}</option>
                                    </select>
                                    <!-- <input type="text" name="city"
                                        placeholder="City" id="city" ngModel required onblur="getLatLong();"> -->
                                </div>
                               
                                <div class="col-lg-6 mt-3">
                                    <input type="text" placeholder="Zip" name="zip" ngModel required>
                                </div>
                                
                                <div class="col-lg-6 mt-3">
                                    <select (ngModelChange)="changeAddType($event)" id="address_type" name="address_type" class="form-control"  ngModel="0" required>
                                        AddressTypeList
                                    
                                        <option [value]="0" selected>Select Address Type</option>
                                        <option *ngFor="let type of AddressTypeList" [value]="type.id" >{{type.address_type}}</option>
                                        <!-- <option value="1" selected>Home</option> -->
                                        <!-- <option value="2">Office</option> -->
                                        <!-- <option value="3">Others</option> -->
                                    </select>
                                </div>
                                <div class="col-lg-6 mt-3" *ngIf="addressOther">
                                    <input type="text" placeholder="Others Address" name="addressOther" ngModel required>
                                   
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="hidden" id="user_lat" name="latitude" ngModel>
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="hidden" id="user_long" name="longitude" ngModel>
                                </div>
                                <div class="col-lg-12 mt-3">
                                    <button class="btn mt-2 btn-addd"
                                    style="background-color: #ff9800;">
                                    Save changes
                                </button>
    <button class="btn mt-2 btn-addd btn-danger pull-right" (click)="resetForm(addressPost)" data-dismiss="modal" aria-label="Close">Cancel</button>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Checkout -->