import { Component, OnInit } from '@angular/core';
import {HomepageService} from '../service/homepage.service';

@Component({
  selector: 'app-termcondition',
  templateUrl: './termcondition.component.html',
  styleUrls: ['./termcondition.component.css']
})
export class TermconditionComponent implements OnInit {
  contentdata:any;
  constructor(private homepageService:HomepageService) { }

  ngOnInit(): void {
    this.homepageService.getTermCondition().toPromise().then((hdata:any)=>{
      this.contentdata=hdata.data[0]['content'];
    });
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
}
