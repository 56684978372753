<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="breadcrumb-box text-center">
                  <ul class="list-unstyled list-inline">
                      <li class="list-inline-item"><a routerLink="home">Home</a></li>
                      <li class="list-inline-item"><span>||</span> Register</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Register -->
<section class="register">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <form  #signupPost="ngForm" (ngSubmit)="onSubmit(signupPost)">
              <!-- <form  #signupPost="ngForm" > -->
                  <h5>Create Your Account</h5>
                  <div class="row">
                      <div class="form-group col-md-6 ">
                          <label>Name*</label>
                          <input type="text" class="form-control" placeholder="Your name" name="vendor_name" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>Company Name*</label>
                          <input type="text" class="form-control"  placeholder="Company Name" name="company_name" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>GST No.(Optional)</label>
                          <input type="text" class="form-control"  placeholder="GST No" name="gst_no" ngModel>
                      </div>
                      <div class="form-group col-md-6 ">
                          <label>Email*</label>
                          <input type="text" class="form-control" placeholder="Email" name="email" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>Mobile No.*</label>
                          <input type="text" class="form-control"  placeholder="Mobile No." name="mobile" ngModel>
                      </div>
                      <div class="form-group col-md-6 ">
                          <label>Alternate Mobile No.</label>
                          <input type="text" class="form-control" placeholder="Alternate Mobile No." name="alternate_mobile" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>State*</label>
                          <input type="text" class="form-control"  placeholder="State" name="state" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>Address*</label>
                          <input type="text" class="form-control"  placeholder="Address" name="address" id="address" onblur="getLatLong();" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                            <input type="hidden" id="user_lat" name="latitude" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                            <input type="hidden" id="user_long" name="longitude" ngModel>
                      </div>

                      <div class="form-group col-md-6">
                          <label>City*</label>
                          <input type="text" class="form-control"  placeholder="City" name="city" id="city" onblur="getLatLong();" ngModel>
                      </div>
                      <div class="form-group col-md-6">
                          <label>Zip*</label>
                          <input type="text" class="form-control"  placeholder="Zip" name="zip" ngModel>
                      </div>

                      <div class="form-group col-md-12">
                        <label for="service_description">Service decription*</label>
                        <textarea class="form-control" id="service_description" rows="3" style="border-radius: 10px;" name="service_description" ngModel></textarea>
                      </div>
                      <div class="form-group col-md-6">
                          <label>Password*</label>
                          <input type="password" class="form-control"  placeholder="Your password" name="password" ngModel>
                      </div>
                      <!-- <input type="hidden" name="phone" name="phone" ngModel="{{mobile}}"> -->
                      <input type="hidden" name="phone" name="phone" >
                      <div class="form-group col-md-6">
                          <label>Confirm Password*</label>
                          <input type="password" class="form-control" placeholder="Confirm password" name="cpassword" ngModel>
                      </div>
                      <div class="col-md-12 text-left">
                          <button type="submit">Submit</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</section>
<!-- End Register -->
