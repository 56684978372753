import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../service/products.service';
import { CartService } from '../service/cart.service';
import { CommonService } from '../service/common.service';
import { NgForm } from '@angular/forms';
import { LiteralMapEntry } from '@angular/compiler/src/output/output_ast';

declare var $: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit {
  productdata: any;
  offer_price: string = '';

  venderList: any = [];
  price: string = '';
  selectedPriceId: string = '';
  selectedColor: number = 0;
  selctedSize: number = 0;
  basePath =
    'https://www.maydtechnologies.com/products/localkorner/localkornerbackend/public/product_image/';
  productImageSrc: string = '';
  cartviewdata: any;
  revewdata: any;
  cartdata: any;
  wcount = 0;
  rlength: any;
  isproductexist: any;
  isreviewcount: any;
  board: any;
  constructor(
    private productService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private common: CommonService
  ) {}
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
    this.getProductDetail();
    this.getProductReview();
    localStorage.removeItem('redirect_from');
    localStorage.removeItem('redirect_pid');
    localStorage.setItem('cart_size', '');
  }

  getProductReview() {
    let product_id = this.route.snapshot.url[1].path;
    this.productService
      .getProductReview(product_id)
      .toPromise()
      .then((cdata: any) => {
        this.revewdata = cdata;
        this.rlength = Object.keys(cdata.data).length;
      });
  }
  getProductDetail() {
    this.offer_price = '';
    this.price = '';

    let product_id = this.route.snapshot.url[1].path;
    this.productService
      .getProductDetail(product_id)
      .toPromise()
      .then((cdata: any) => {
        this.productdata = cdata;
        this.productdata?.attributeList.forEach((element: any) => {
          let attrList = element.attribute_values;
          if (element.name == 'Color') {
            this.selectedColor = attrList[0].id;
          }
          if (element.name != 'Color') {
            this.selctedSize = attrList[0].id;
          }
          console.log('defColor', this.selectedColor, this.selctedSize);
        });

        if (this.selectedColor || this.selctedSize) {
          this.changeAmount('', '');
        }

        if (Object.keys(cdata.similerproduct).length == 0) {
          this.isproductexist = false;
        } else {
          this.isproductexist = true;
        }
      });
  }

  checkAddStock()
  {
    let val=false;
    if (this.venderList.length > 0) {
      val = Number(this.venderList[0].vendor_stock) ? true : false;
    }
    return val;
  }

  changeAmount(val: any, type: string) {
    let imgSrc = '';
    this.productImageSrc = '';
    const venderPriceList = this.productdata?.variantData?.variantVendorPrice;
    const img = this.productdata?.variantData?.variantGallery;

    if (type == 'changeSize' && val) {
      this.selctedSize = val;
    }
    if (type == 'changeColor' && val) {
      this.selectedColor = val.target.value;
    }

    let colrSizeNo: any = '';
    if (this.selectedColor && this.selctedSize) {
      colrSizeNo = this.selectedColor + ',' + this.selctedSize;
    } else {
      if (this.selectedColor) {
        colrSizeNo = this.selectedColor;
      }
      if (this.selctedSize) {
        colrSizeNo = this.selctedSize;
      }
    }
    const arr = venderPriceList.find(
      (item: any) => item.attribute_value_id_list == colrSizeNo
    );

    let list = venderPriceList.find((item: any) => item.variant == colrSizeNo);

    if (list.vendors.length > 0) {
      this.venderList = list.vendors;

      let venderObj = {
        venderList: this.venderList,
        productId: this.route.snapshot.url[1].path,
        selectedPriceId: this.venderList[0]?.variant_id,
        varientListId: colrSizeNo,
      };
      localStorage.setItem('sellersList', JSON.stringify(venderObj));
      this.selectedPriceId = this.venderList[0]?.variant_id;
      this.offer_price = this.venderList[0]?.vendor_offer_price;
      this.price = this.venderList[0]?.vendor_price;
    }

    img.forEach((element: any) => {
      const src = element.filter(
        (item: any) => item.variant_id == this.selectedPriceId
      );
      if (src.length > 0) {
        this.productImageSrc = this.basePath + src[0]?.image;
      }
    });

    console.log('Find offer_price ,price', this.offer_price, this.price);
    console.log('Image src', this.productImageSrc);
  }

  lgbtnclick(pid: any, qty: any) {
    // const attribute_value_id_list = this.selectedColor + ',' + this.selctedSize;
    let attribute_value_id_list = this.getCommaList(
      this.selectedColor,
      this.selctedSize
    );
    if (localStorage.getItem('user_id') === null) {
      localStorage.setItem('redirect_from', 'detail');
      localStorage.setItem('redirect_pid', pid);
      this.router.navigate(['/login']);
    } else {
      var size = localStorage.getItem('cart_size');
      this.productService
        .addToCart(
          pid,
          this.venderList[0].vendor_type,
          qty,
          size,
          this.selectedPriceId,
          attribute_value_id_list
        )
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
          this.productService.cartCountSource.next(data.cart_qty);
          this.cartService.getCartViewdata().subscribe((cdata) => {
            this.cartviewdata = cdata;
          });
        });
    }
  }

  getCommaList(val1: number, val2: number) {
    const list = [];
    val1 ? list.push(val1) : '';
    val2 ? list.push(val2) : '';
    return list.join(',');
  }

  bynowclick(pid: any, qty: any) {
    if (localStorage.getItem('user_id') === null) {
      localStorage.setItem('redirect_from', 'detail');
      localStorage.setItem('redirect_pid', pid);
      this.router.navigate(['/login']);
    } else {
      const list = [];
      // let attribute_value_id_list = "";
      // const attribute_value_id_list = this.selectedColor + ',' + this.selctedSize;
      // if(this.selectedColor)
      // {

      //   list.push(this.selectedColor);
      // }
      // if(this.selctedSize)
      // {

      //   list.push(this.selctedSize);
      // }

      let attribute_value_id_list = this.getCommaList(
        this.selectedColor,
        this.selctedSize
      );
      var size = localStorage.getItem('cart_size');
      this.productService
        .addToCart(
          pid,
          this.venderList[0].vendor_type,
          qty,
          size,
          this.selectedPriceId,
          attribute_value_id_list
        )
        .toPromise()
        .then((data: any) => {
          if (data.status == true) {
            this.router.navigate(['/checkout']);
          } else {
            this.common.showError('', 'Something went wrong');
          }
        });
    }
  }

  onReview(data: NgForm) {
    let postdata = data.value;
    if (localStorage.getItem('user_id') === null) {
      this.common.showError('', 'Please login');
    } else {
      if ($('.rating-value').val() == 0) {
        this.common.showError('', 'Rating can not blank');
        return;
      }
      if (postdata.review == '') {
        this.common.showError('', 'Review can not be blank');
        return;
      }
      let pData = {
        user_id: localStorage.getItem('user_id'),
        rating: $('.rating-value').val(),
        review: postdata.review,
        product_id: this.route.snapshot.url[1].path,
      };
      this.productService
        .submitReview(pData)
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
        });
      location.reload();
    }
  }

  callFunction(id: any, qty: any) {
    if (localStorage.getItem('user_id') === null) {
      this.common.showError('', 'Please login');
    } else {
      var size = localStorage.getItem('cart_size');
      this.productService
        .addToCart(id, '', qty, size, '', '')
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
          this.productService.cartCountSource.next(data.cart_qty);
          this.cartdata = data.status;
        });
    }
  }

  addToWishlist(id: any) {
    if (localStorage.getItem('user_id') === null) {
      this.common.showError('', 'Please Login');
    } else {
      this.productService
        .addToWishlist(id)
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
          console.log(data);
          console.log(data.wcount);
          this.productService.Wcount.next(data.wcount);
          this.productService.Wcount.next(data.wcount);
          this.wcount = data.wcount;
        });
    }
  }
  get_size(unit: any, size: any) {
    localStorage.setItem('cart_size', size + ' ' + unit);
  }
}
