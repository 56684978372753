import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { CommonService } from '../service/common.service';
import { ProductsService } from '../service/products.service';

@Component({
  selector: 'app-all-sellers-list',
  templateUrl: './all-sellers-list.component.html',
  styleUrls: ['./all-sellers-list.component.css']
})
export class AllSellersListComponent implements OnInit {

  sellersList:any=[];
  venderListObj:any=[];
  constructor(
    private productService: ProductsService,
    private router: Router,
    private cartService: CartService,
    private common: CommonService
  ) { }

  ngOnInit(): void {


    let data =localStorage.getItem('sellersList');
    if(data!=null)
    {
      
      this.venderListObj=JSON.parse(data)
      this.sellersList= (this.venderListObj.venderList) ;
      console.log("this.sellersList",this.sellersList)
    }

  }

  addToCart(qty: number, sellers:any) {

    if (localStorage.getItem('user_id') === null) {
      localStorage.setItem('redirect_from', 'detail');
      localStorage.setItem('redirect_pid', this.venderListObj.selectedPriceId);
      this.router.navigate(['/login']);
    } else {
      var size = localStorage.getItem('cart_size');
      this.productService
        .addToCart(
          this.venderListObj.productId,sellers.vendor_type,
          qty,
          size,
          sellers.variant_id,
          this.venderListObj.varientListId
        )
        .toPromise()
        .then((data: any) => {
          this.common.showSuccess('', data.msg);
          this.productService.cartCountSource.next(data.cart_qty);
          this.cartService.getCartViewdata().subscribe((cdata) => {
       
          });
        });
    }
  }
  checkStock(stock :number)
  {

    return Number(stock)?true:false;

  }
}
