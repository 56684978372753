import { Component, OnInit } from '@angular/core';
import {FooterService} from '../service/footer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerdata:any;
  bdata:any;
  constructor(private footerService:FooterService,private router:Router) { }
  address:any;
  email:any;
  phone:any;
  facebook_link:any;
  twitter_link:any;
  linkdin_link:any;
  company_name:any;
  isLoggedIn=false;

  ngOnInit(): void {
      this.footerService.getFooterData().toPromise().then((data:any)=>{
      this.footerdata=data;
      this.company_name=data.company[0].company_name;
      this.address=data.company[0].address;
      this.email=data.company[0].email;
      this.phone=data.company[0].phone;
      this.facebook_link=data.company[0].facebook_link;
      this.twitter_link=data.company[0].twitter_link;
      this.linkdin_link=data.company[0].linkdin_link;
      if(localStorage.getItem('user_name'))
      {
        this.isLoggedIn = true;
      }
      else
      {
        this.isLoggedIn = false;
      }
    });
  }
  onNavigate(pid: any)
  {
    this.router.navigate(['/product-list/by-category/',pid])
        .then(() => {
          window.location.reload();
        });
  }
}
