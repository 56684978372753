import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http:HttpClient) { }
  getBlogList(){
    let url = environment.base_url;
    return this.http.get(url+'blog-list');
  }
  getBlogDetail(blog_id:any){
    let url = environment.base_url;
    var postData = {
      id:blog_id
    }
    return this.http.post(url+'blog-detail',postData);
  }
  getBlogComment(blog_id:any){
    let url = environment.base_url;
    var postData = {
      blog_id:blog_id
    }
    return this.http.post(url+'get-blog-comment',postData);
  }
  saveBlogComment(blog_id:any,data:any)
  {
    let url =environment.base_url
    let postdata = {
      name:data.name,
      email:data.email,
      comment:data.comment,
      blog_id:blog_id,
       }
    return this.http.post(url+"save-blog-comment",postdata);
  }

}
