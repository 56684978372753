import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { NgForm } from '@angular/forms';
import { CartService } from '../service/cart.service';
declare var $: any;

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css'],
})
export class UserprofileComponent implements OnInit {
  user_name: any;
  orderlist: any;
  useraddress: any;
  user_id: any;
  fname: any;
  lname: any;
  email: any;
  phone: any;
  address: any;
  city: any;
  latitude: any;
  longitude: any;
  state: any;
  zip: any;
  address_id: any;
  address_type: any;
  password: any;
  userid: any;
  order_detail: any;
  order_product: any;
  shipping_address: any;
  order_id: any;
  payment_id: any;
  coupon_code: any;
  coupon_amount: any;
  shipping_charge: any;
  grand_total: any;
  order_status: any;
  addressOther:boolean=false;
  stateList:any=[];
  cityList:any=[];
  AddressTypeList:any=[];
  constructor(
    private userService: UserService,
    private router: Router,
    private common: CommonService,
    private cartService: CartService,

  ) {}

  ngOnInit(): void {
    this.user_name = localStorage.getItem('user_name');
    this.getState();
    this.getOtherType();
  }

  getState() {
    this.cartService.getState().subscribe((state_Data:any) => {
     
      if(state_Data.data.length>0)
      {
        this.stateList=state_Data.data;

      }
      
    });
  }


  getOtherType() {
    this.cartService.getOtherType().subscribe((Address_Data:any) => {
     
      if(Address_Data.data.length>0)
      {
        this.AddressTypeList=Address_Data.data;

      }
      
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
  getUserOrder() {
    let user_id = localStorage.getItem('user_id');
    this.userService
      .getUserOrder(user_id)
      .toPromise()
      .then((mndata: any) => {
        this.orderlist = mndata.data;
      });
  }
  getUserAddress() {
    let user_id = localStorage.getItem('user_id');
    this.userService
      .getUserAddress(user_id)
      .toPromise()
      .then((mndata: any) => {
        this.useraddress = mndata.data;
        this.user_id = user_id;
      });
  }
  getUserProfile() {
    this.userService
      .getUserProfile()
      .toPromise()
      .then((mndata: any) => {
        this.fname = mndata.data.fname;
        this.lname = mndata.data.lname;
        this.email = mndata.data.email;
        this.phone = mndata.data.phone;
        this.password = mndata.data.password;
        this.userid = mndata.data.user_id;
      });
  }
  onUpdate(data: any) {
    this.userService
      .updateUserProfile(data)
      .toPromise()
      .then((mndata: any) => {
        alert(mndata.msg);
      });
  }
  get_address_detail(address_id: any) {
    this.userService
      .getAddressDetail(address_id)
      .toPromise()
      .then((shipping_address: any) => {
        this.address_id = shipping_address.data.id;
        this.fname = shipping_address.data.fname;
        this.lname = shipping_address.data.lname;
        this.email = shipping_address.data.email;
        this.phone = shipping_address.data.phone;
        this.address = shipping_address.data.address;
        this.city = shipping_address.data.city;
        this.state = shipping_address.data.state;
        this.zip = shipping_address.data.zip;
        this.latitude = shipping_address.data.latitude;
        this.longitude = shipping_address.data.longitude;
        this.address_type = shipping_address.data.address_type;
      });
  }
  getOrderDetail(order_id: any) {
    this.userService
      .getOrderDetail(order_id)
      .toPromise()
      .then((orddata: any) => {
        console.log(orddata);
        this.order_id = orddata.order_detail.order_id;
        this.payment_id = orddata.order_detail.payment_id;
        this.coupon_code = orddata.order_detail.coupon_code;
        this.coupon_amount = orddata.order_detail.coupon_amount;
        this.shipping_charge = orddata.order_detail.shipping_charge;
        this.grand_total = orddata.order_detail.grand_total;
        this.order_status = orddata.order_detail.order_status;

        this.order_product = orddata.order_product;

        this.fname = orddata.shipping_address.fname;
        this.lname = orddata.shipping_address.lname;
        this.email = orddata.shipping_address.email;
        this.phone = orddata.shipping_address.phone;
        this.address = orddata.shipping_address.address;
        this.city = orddata.shipping_address.city;
        this.state = orddata.shipping_address.state;
        this.zip = orddata.shipping_address.zip;
        this.address_type = orddata.shipping_address.address_type;
      });
  }

  cancelOrder(order_id: string) {
    let user_id = localStorage.getItem('user_id');
    var result = confirm('Do you want to cancel this order?');
    if (result && user_id) {
      this.userService
        .cancelOrder(order_id, user_id)
        .toPromise()
        .then((orddata: any) => {
          console.log(orddata);
          this.getUserOrder();
        });
    }
  }

  delete_address(address_id: any) {
    var result = confirm('Want to delete this address ?');
    if (result) {
      this.userService
        .deleteaddress(address_id)
        .toPromise()
        .then((addressdata: any) => {
          if (addressdata.status == true) {
            this.common.showSuccess('', addressdata.msg);
            this.getUserAddress();
          } else {
            this.common.showError('', addressdata.msg);
          }
        });
    } else {
      this.common.showError('', 'Action cancled');
    }
  }

  validateFields(formFields:any)
  {
    let data = formFields;
    
    let isvalidate = true;
    if (data.fname == '') {
      this.common.showError('', 'First Name can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.lname == '') {
      this.common.showError('', 'Last Name can not be blank');
      isvalidate=false;
      return isvalidate;
    }
    if (data.phone == '') {
      this.common.showError('', 'Mobile can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.address == '') {
      this.common.showError('', 'Address can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.city == '0') {
      this.common.showError('', 'Please select City name');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.state == '0') {
      this.common.showError('', 'Please select State name');
      isvalidate=false;
      return isvalidate;
    }
    if (data.zip == '') {
      this.common.showError('', 'Zip can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.address_type == '') {
      this.common.showError('', 'Address Type can not be blank');
      isvalidate=false;
      return isvalidate;
    } else {
      isvalidate = true;
      if( this.addressOther==true)
      {
        if(data.addressOther == '')
        {
          this.common.showError('', 'Others Address can not be blank');
          isvalidate=false;
          return isvalidate;
        }
      }
    }
    return isvalidate;
  }

  addNewAddress(postdata: NgForm) {
   
    let checkFormBlank = this.validateFields(postdata.value);
    if ( checkFormBlank== true) {
      let data = postdata.value;
      let pData = {
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        ship_user_id: localStorage.getItem('user_id'),
        latitude: $('#nuser_lat').val(),
        longitude: $('#nuser_long').val(),
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        address_type: data.address_type,
        address_type_name : data.addressOther!=undefined? data.addressOther:''
      };
      this.userService
        .addNewAddress(pData)
        .toPromise()
        .then((udata: any) => {
          if (udata.status == true) {
            this.common.showSuccess('', udata.msg);
            // postdata.reset();
            this.resetForm(postdata);
            this.getUserAddress();
            $('#addressmodal').modal('toggle');
          } else {
            this.common.showError('', udata.msg);
          }
        });
    }
    //  else {
    //   this.common.showError('', 'Something went wrong');
    // }
  }

  changeAddType(type:string)
  {
    if(type=="5")
    {

      this.addressOther=true;
    }
    else{

      this.addressOther=false;

    }

    console.log('Addres Type',type)

  }

  changeState(id:string)
  {
    this.getCity(id);

  }

  getCity(state_id:string) {
    this.cartService.getCitybyStateId(state_id).subscribe((city_Data:any) => {
     
      if(city_Data.data.length>0)
      {
        this.cityList=city_Data.data;

      }
      
    });
  }

  // addNewAddress(postdata: NgForm) {
  //   let data = postdata.value;
  //   let isvalidate = true;
  //   if (data.fname == '') {
  //     this.common.showError('', 'First Name can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.lname == '') {
  //     this.common.showError('', 'Last Name can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.phone == '') {
  //     this.common.showError('', 'Mobile can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.address == '') {
  //     this.common.showError('', 'Address can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.city == '') {
  //     this.common.showError('', 'City can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.state == '') {
  //     this.common.showError('', 'State can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.zip == '') {
  //     this.common.showError('', 'Zip can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.address_type == '') {
  //     this.common.showError('', 'Address Type can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (isvalidate == true) {
  //     let pData = {
  //       fname: data.fname,
  //       lname: data.lname,
  //       email: data.email,
  //       ship_user_id: data.ship_user_id,
  //       latitude: $('#nuser_lat').val(),
  //       longitude: $('#nuser_long').val(),
  //       phone: data.phone,
  //       address: data.address,
  //       city: data.city,
  //       state: data.state,
  //       zip: data.zip,
  //       address_type: data.address_type,
  //     };
  //     this.userService
  //       .addNewAddress(pData)
  //       .toPromise()
  //       .then((udata: any) => {
  //         if (udata.status == true) {
  //           this.common.showSuccess('', udata.msg);
  //           postdata.reset();
  //           this.getUserAddress();
  //           $('#addressmodal').modal('toggle');
  //         } else {
  //           this.common.showError('', udata.msg);
  //         }
  //       });
  //   } else {
  //     this.common.showError('', 'Something went wrong');
  //   }
  // }

  resetForm(formData:any)
  {

    formData.reset();
    $('#address_type').val(0);
    $('#city').val(0);
    $('#State').val(0);
  }

  updateAddress(postdata: NgForm) {
    let data = postdata.value;
    let isvalidate = true;
    if (data.fname == '') {
      this.common.showError('', 'First Name can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.lname == '') {
      this.common.showError('', 'Last Name can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.phone == '') {
      this.common.showError('', 'Mobile can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.address == '') {
      this.common.showError('', 'Address can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.city == '') {
      this.common.showError('', 'City can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.state == '') {
      this.common.showError('', 'State can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.zip == '') {
      this.common.showError('', 'Zip can not be blank');
    } else {
      isvalidate = true;
    }
    if (data.address_type == '') {
      this.common.showError('', 'Address Type can not be blank');
    } else {
      isvalidate = true;
    }
    if (isvalidate == true) {
      let pData = {
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        address_id: data.ship_address_id,
        latitude: $('#uuser_lat').val(),
        longitude: $('#uuser_long').val(),
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        address_type: data.address_type,
      };
      this.userService
        .updateAddress(pData)
        .toPromise()
        .then((udata: any) => {
          if (udata.status == true) {
            this.common.showSuccess('', udata.msg);
            this.getUserAddress();
            $('#myModal').modal('toggle');
          } else {
            this.common.showError('', udata.msg);
          }
        });
    } else {
      this.common.showError('', 'Something went wrong');
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
