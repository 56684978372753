import { ChangeDetectorRef,Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import { PaymentService } from '../service/payment.service';
import { CommonService } from '../service/common.service';
import { UserService } from '../service/user.service';
import { ProductsService } from '../service/products.service';
import { NgForm } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  cartviewdata: any;
  useraddress: any;
  slot_time: any;
  slotdate: any;
  user_id: any;
  showremove: any;
  showapply: any;
  coupon_code: any="";
  addressOther:boolean=false;
  stateList:any=[];
  cityList:any=[];
  AddressTypeList:any=[];

  constructor(
    private userService: UserService,
    private cartService: CartService,
    private router: Router,
    private payment: PaymentService,
    private common: CommonService,
    private productsService: ProductsService,
  private changeDetector:  ChangeDetectorRef
  ) {}

  ngOnInit(): void {

  //   let obj={id:0,name:'Select'};
  // this.stateList.push(obj);

    this.cartViewData();
    this.getState();
    this.getOtherType();
  }


  changeAddType(type:string)
  {
    if(type=="5")
    {

      this.addressOther=true;
    }
    else{

      this.addressOther=false;

    }

    console.log('Addres Type',type)

  }
  cartViewData() {
    this.cartService
      .getCartViewdata()
      .toPromise()
      .then((cdata: any) => {
        this.cartviewdata = cdata;

        if (this.cartviewdata.data.length == 0) {
          this.router.navigate(['/']);
        }
        if (!localStorage.getItem('user_id')) {
          this.router.navigate(['/login']);
        }
        this.coupon_code = localStorage.getItem('coupon_code');
        if (localStorage.getItem('coupon_code') == '') {
          this.showremove = false;
          this.showapply = true;
        } else {
          this.showremove = true;
          this.showapply = false;
        }
        this.getUserAddress();
      });

   
    this.cartService.getSlotDate().toPromise().then((sdata: any) => {
        this.slotdate = sdata.slotdate;
      });
  }

  changeAddress(id: string) {
    let sub_total: string = this.cartviewdata?.subtotal.replace(/\,/g, '');
    this.coupon_code = localStorage.getItem('coupon_code');
    let body = {
      address_id: id,
      sub_total: Number(sub_total),
      coupon_code:this.coupon_code
    };
    this.payment
      .saveChangeAddress(body)
      .toPromise()
      .then((pdata: any) => {
        this.cartviewdata.subtotal = pdata.data[0].subtotal;
        this.cartviewdata.shipping_charge = pdata?.data[0]?.shipping_charge;
        this.cartviewdata.grand_total = pdata?.data[0]?.grand_total;
        this.cartviewdata.deliveryMsg= pdata?.data[0]?.deliveryMsg;
      });
  }


  getState() {
    this.cartService.getState().subscribe((state_Data:any) => {
     
      if(state_Data.data.length>0)
      {
        this.stateList=state_Data.data;

      }
      
    });
  }

  getCity(state_id:string) {
    this.cartService.getCitybyStateId(state_id).subscribe((city_Data:any) => {
     
      if(city_Data.data.length>0)
      {
        this.cityList=city_Data.data;

      }
      
    });
  }

  getOtherType() {
    this.cartService.getOtherType().subscribe((Address_Data:any) => {
     
      if(Address_Data.data.length>0)
      {
        this.AddressTypeList=Address_Data.data;

      }
      
    });
  }

  changeState(id:string)
  {
    this.getCity(id);

  }

  getUserAddress() {
    this.cartService.getUserAddress().subscribe((udata:any) => {
      this.useraddress = udata;
      let arr=udata?.data;
      var lastItemArr = arr[arr?.length - 1];
      if(lastItemArr)
      {
        this.changeAddress(lastItemArr?.id);
      }
    });
  }
  onSubmit(postdata: any) {
    var slotdate = $('#slot_day :selected').val();
    var slot_time = $('#slot_time :selected').val();
    let address = $('input[type=radio][name=address]:checked').val();

    if (address == undefined) {
      this.common.showError('', 'Address can not be blank');
      return;
    } else {
      postdata.address = address;
    }
    if (slotdate == '') {
      this.common.showError('', 'Slot date can not be blank');
      return;
    } else {
      postdata.slotdate = slotdate;
    }
    if (slot_time == '') {
      this.common.showError('', 'Slot time can not be blank');
      return;
    } else {
      postdata.slot_time = slot_time;
    }

    localStorage.setItem('checkoutdata', JSON.stringify(postdata));
    localStorage.setItem('paymentdata', JSON.stringify(this.cartviewdata));
    let data = JSON.stringify(this.cartviewdata);

    if (postdata.payment == 'cod') {
      let paymentdata = localStorage.getItem('paymentdata');
      let checkdata = JSON.stringify(postdata);

      console.log('paymentdata', paymentdata);
      let body = {
        product_data: paymentdata,
        address_id: checkdata,
        payment_id: '',
        user_id: localStorage.getItem('user_id'),
      };
      this.payment
        .saveOrderData(body)
        .toPromise()
        .then((pdata: any) => {
          this.common.showSuccess('', pdata.msg);
          if (pdata.status == true) {
            localStorage.removeItem('paymentdata');
            localStorage.removeItem('checkoutdata');
            localStorage.removeItem('coupon_code');
            localStorage.setItem('order_no', pdata.order_no);

            this.router.navigate(['/order-status']).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['/order-status']);
          }
        });
    } else {
      this.router.navigate(['/payment']);
    }
  }
  getSlot() {
    let slot_day = $('#slot_day :selected').val();
    this.cartService
      .getSlot(slot_day)
      .toPromise()
      .then((sdata: any) => {
        this.slot_time = sdata.slot_time;
        console.log(this.slot_time);
      });
  }


  validateFields(formFields:any)
  {
    let data = formFields;
    
    let isvalidate = true;
    if (data.fname == '') {
      this.common.showError('', 'First Name can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.lname == '') {
      this.common.showError('', 'Last Name can not be blank');
      isvalidate=false;
      return isvalidate;
    }
    if (data.phone == '') {
      this.common.showError('', 'Mobile can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.address == '') {
      this.common.showError('', 'Address can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.city == '0') {
      this.common.showError('', 'Please select City name');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.state == '0') {
      this.common.showError('', 'Please select State name');
      isvalidate=false;
      return isvalidate;
    }
    if (data.zip == '') {
      this.common.showError('', 'Zip can not be blank');
      isvalidate=false;
      return isvalidate;
    } 
    if (data.address_type == '') {
      this.common.showError('', 'Address Type can not be blank');
      isvalidate=false;
      return isvalidate;
    } else {
      isvalidate = true;
      if( this.addressOther==true)
      {
        if(data.addressOther == '')
        {
          this.common.showError('', 'Others Address can not be blank');
          isvalidate=false;
          return isvalidate;
        }
      }
    }
    return isvalidate;
  }
  // validateFields(formFields:any)
  // {
  //   let data = formFields;
    
  //   let isvalidate = true;
  //   if (data.fname == '') {
  //     this.common.showError('', 'First Name can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.lname == '') {
  //     this.common.showError('', 'Last Name can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.phone == '') {
  //     this.common.showError('', 'Mobile can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.address == '') {
  //     this.common.showError('', 'Address can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.city == '0') {
  //     this.common.showError('', 'Please select City name');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.state == '0') {
  //     this.common.showError('', 'Please select State name');
  //   } else {

  //     let thi
  //     isvalidate = true;
  //   }
  //   if (data.zip == '') {
  //     this.common.showError('', 'Zip can not be blank');
  //   } else {
  //     isvalidate = true;
  //   }
  //   if (data.address_type == '') {
  //     this.common.showError('', 'Address Type can not be blank');
  //   } else {
  //     isvalidate = true;
  //     if( this.addressOther==true)
  //     {
  //       if(data.addressOther == '')
  //       {
  //         this.common.showError('', 'Others Address can not be blank');
  //       }
  //     }


  //   }
  // }
  addNewAddress(postdata: NgForm) {
   
    let checkFormBlank = this.validateFields(postdata.value);
    if ( checkFormBlank== true) {
      let data = postdata.value;
      let pData = {
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        ship_user_id: localStorage.getItem('user_id'),
        latitude: $('#user_lat').val(),
        longitude: $('#user_long').val(),
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        address_type: data.address_type,
        address_type_name : data.addressOther!=undefined? data.addressOther:''
      };
      this.userService
        .addNewAddress(pData)
        .toPromise()
        .then((udata: any) => {
          if (udata.status == true) {
            this.common.showSuccess('', udata.msg);
            // postdata.reset();
            this.resetForm(postdata);
            this.getUserAddress();
            $('#addressmodal').modal('toggle');
          } else {
            this.common.showError('', udata.msg);
          }
        });
    }
    //  else {
    //   this.common.showError('', 'Something went wrong');
    // }
  }

  resetForm(formData:any)
  {

    formData.reset();
    $('#address_type').val(0);
    $('#city').val(0);
    $('#State').val(0);
  }

  onApplyCoupon(postData: any) {
    postData.user_id = localStorage.getItem('user_id');
    if (postData.coupon_code == '') {
      this.common.showError('', 'Coupon code can not be blank');
    }
    this.productsService
      .applyCouponCode(postData)
      .toPromise()
      .then((cpdata: any) => {
        if (cpdata.status == true) {
          localStorage.setItem('coupon_code', cpdata.coupon_code);
          this.cartViewData();
          this.common.showSuccess('', cpdata.msg);
        } else {
          this.common.showError('', cpdata.msg);
          localStorage.setItem('coupon_code', '');
        }
      });
  }
  removeCoupon() {
    localStorage.setItem('coupon_code', '');
    this.cartViewData();
  }
}
