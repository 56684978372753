import { Component, OnInit } from '@angular/core';
import {HomepageService} from '../service/homepage.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  contentdata:any;
  constructor(private homepageService:HomepageService) { }

  ngOnInit(): void {
    this.homepageService.getAboutUs().toPromise().then((hdata:any)=>{
      this.contentdata=hdata.data[0]['content'];
    });
  }

}
