import { Component, OnInit } from '@angular/core';
import {RegisterService} from '../service/register.service';
import {Router} from '@angular/router';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
isHidden = false;
    constructor(private registerService:RegisterService,
              private router: Router,
              private common:CommonService ) { }

  ngOnInit(): void {
  }

    onSubmit(data:any,isHidden:any)
  {
    if(isHidden == false)
    {
      this.registerService.sendForgetOtp(data).toPromise().then((udata:any)=>{
        // alert("hello");
        if(udata.status == true)
        {
          this.common.showSuccess("",udata.msg);
          this.isHidden = true;
          localStorage.setItem("otp", udata.OTP);
          localStorage.setItem("phone", udata.phone);

        }
        else
        {
          this.common.showError("",udata.msg);
        }
      });
    }
    else
    {
      let otp = localStorage.getItem("otp");
      let user_otp = data.otp;
      if(otp==user_otp)
      {
        // alert("OTP verified");
        this.common.showSuccess("","OTP verified")
        localStorage.removeItem("otp");
        this.router.navigate(['/change-password']);
      }
      else
      {
        // alert("Invalid otp");
        this.common.showError("","Invalid otp");
      }
    }
  }
  resendOtp()
  {
    let data = {
      phone:localStorage.getItem("phone")
    }
    this.registerService.sendForgetOtp(data).toPromise().then((udata:any)=>{
      if(udata.status == true)
      {
        this.common.showSuccess("",udata.msg);
        this.isHidden = true;
        localStorage.setItem("otp", udata.OTP);
        localStorage.setItem("phone", udata.phone);

      }
      else
      {
          this.common.showError("",udata.msg);
      }
    });
  }

}
