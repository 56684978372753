import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {




  constructor(private http:HttpClient,
    private common:CommonService) { }

  getSlider(){
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-slider');
  }
  getBrand(){
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-brand');
  }
  getBanner(){
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-banner');
  }
  getBlog(){
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'blog-list');
  }
  getHomePageProduct()
  {
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'homepage-product-list');
  }
  saveSubscribe(postData:any)
  {
    let server_url = environment.base_url;
    return this.http.post(server_url+ 'subscribe',postData);
    
  }
  getAboutUs()
  {
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-about-us');
  }
  getPrivacyPolicy()
  {
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-privacy-policy');
  }
  getShippingPolicy()
  {
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-shipping-policy');
  }
  getTermCondition()
  {
    let server_url = environment.base_url;
    return this.http.get(server_url+ 'get-term-condition');
  }
  getHomepageCategoryProduct(sub_cat_id:any)
  {
    let url = environment.base_url;
    let postdata = {
      sub_cat_id:sub_cat_id
       }
    return this.http.post(url+'homepage-category-product',postdata);
  }
}
