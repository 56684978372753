import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  productlist:any;
        // export class ProductsService {
      // --------------samarjeet
      public Wcount = new BehaviorSubject(0); // to assign value
      sharedWcount = this.Wcount.asObservable(); // to take value
      increasWcount() {
        this.sharedWcount.subscribe(count=>this.Wcount.next(count+1))
      }
      decreasWcount()
      {
        this.sharedWcount.subscribe(count=>this.Wcount.next(count-1))
      }



      public cartCountSource = new BehaviorSubject(0); // to assign value
      cartCount = this.cartCountSource.asObservable(); // to take value
      
      // increasCcount() {
      //     this.cartCount.subscribe(count=>this.cartCountSource.next(count+1))
      //   }

      // decreasCcount()
      //   {
      //     this.cartCount.subscribe(count=>this.cartCountSource.next(count-1))
      //   }
// -----------------

  constructor(private http:HttpClient) { }

  changeCartCount(cartcount: any) {
    this.cartCountSource.next(cartcount)
  }
  
  addToCart(id:any,vendor_type:string ,qty:any,size:any,variant_id:any,attribute_value_id_list:any)
  {
    
    let url = environment.base_url;
    var cartdata = {
      product_id:id,
      vendor_type:vendor_type,
      user_id:localStorage.getItem('user_id'),
      qty:qty,
      size:size,
      variant_id ,attribute_value_id_list 
    }

    let data= this.http.post(url+'add-to-cart',cartdata);
    return data;
  }

  addToWishlist(id:any)
  {
    let url = environment.base_url;
    var wishdata = {
      product_id:id,
      user_id:localStorage.getItem('user_id'),
    }

    return this.http.post(url+'add-to-wishlist',wishdata);;

  }


  getBestSeller(order_by:any,barnd_filter:any,categoryId:any){
    let url = environment.base_url;
    let postData = {
      order_by:order_by,
      brand_id:barnd_filter,
      categoryId:categoryId
    }
    return this.http.post(url+'best-seller-product',postData)
  }


  getTodayDeal(order_by:any,barnd_filter:any,categoryId:any){
    let url = environment.base_url;
    let postData = {
      order_by:order_by,
      brand_id:barnd_filter,
      categoryId:categoryId
    }
    console.log(postData);
    return this.http.post(url+'today-deal-product',postData)
  }


  getFashionProduct(order_by:any,barnd_filter:any,categoryId:any){
    let url = environment.base_url;
    let postData = {
      order_by:order_by,
      brand_id:barnd_filter,
      categoryId:categoryId
    }
    return this.http.post(url+'get-fashion-product',postData)
  }


  getProductBySubcategory(subcategoryid:any,order_by:any,barnd_filter:any,categoryId:any)
  {
    let url = environment.base_url;

    var subcat = {
        subcategory_id:subcategoryid,
        order_by:order_by,
        brand_id:barnd_filter,
        categoryId:categoryId
    }
    return this.http.post(url+'product-by-subcategory',subcat);
  }


  getProductByCategory(catid:any,order_by:any,barnd_filter:any,categoryId:any)
  {
    let url = environment.base_url;

    var postdata = {
        cat_id:catid,
        order_by:order_by,
        brand_id:barnd_filter,
        categoryId:categoryId
    }
    return this.http.post(url+'product-by-category',postdata);
  }


  getProductBySearch(searchkey:any,order_by:any,barnd_filter:any,categoryId:any)
  {
    let url = environment.base_url;
    var postdata = {
      search_key:searchkey,
      order_by:order_by,
      brand_id:barnd_filter,
      categoryId:categoryId
  }
    return this.http.post(url+'product-by-search',postdata);
  }


  getCartDetail()
  {
    let url = environment.base_url;

    var postData = {
      user_id:localStorage.getItem('user_id'),
      coupon_code:localStorage.getItem('coupon_code')
    }
    return this.http.post(url+'get-cart-detail',postData);
  }

  applyCouponCode(postData:any)
  {
    let url = environment.base_url;

    var pdata = {
      coupon_code:postData.coupon_code,
      user_id:postData.user_id,
      sub_total:postData.sub_total
    }
    return this.http.post(url+'apply-coupon',pdata);
  }

  removefromcart(cartid:any)
  {
    let url = environment.base_url;
    var pdata = {
      cart_id:cartid
    }
    return this.http.post(url+'remove-from-cart',pdata);
  }

  getProductDetail(product_id:any)
  {
    let url = environment.base_url;
    var pdata = {
      product_id:product_id,
      user_id:localStorage.getItem('user_id'),
    }
    return this.http.post(url+'product-detail',pdata);
  }

  getWishlist()
  {
    let url = environment.base_url;
    var wishdata = {
      user_id:localStorage.getItem('user_id'),
    }
    return  this.http.post(url+'get-wishlist',wishdata);
  }

  moveToCart(wid:any)
  {
    let url = environment.base_url;
    var wishdata = {
      wishlist_id:wid,
    }
    return this.http.post(url+'move-to-cart',wishdata);
  }
  removeFromWishlist(wid:any)
  {
    let url = environment.base_url;
    var wishdata = {
      wishlist_id:wid,
    }
    return this.http.post(url+'remove-from-wishlist',wishdata);
  }

  submitReview(postData:any)
  {
    let url = environment.base_url;
    return this.http.post(url+'submit-review',postData);
  }

  getProductReview(product_id:any)
  {
    let url = environment.base_url;
    var postdata = {
      product_id:product_id,
    }
    return this.http.post(url+'product-review-list',postdata);
  }
  getCartCount()
  {
      let url = environment.base_url;
      var cartdata = {
        user_id:localStorage.getItem('user_id'),
      }
      return  this.http.post(url+'get-cartcount',cartdata);
  }
  getWishlistCount()
  {
      let url = environment.base_url;
      var wishlistdata = {
        user_id:localStorage.getItem('user_id'),
      }
      return  this.http.post(url+'get-wishlistcount',wishlistdata);
  }
}
