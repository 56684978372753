import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {BlogService} from '../service/blog.service';
import { CommonService } from '../service/common.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-blogdetail',
  templateUrl: './blogdetail.component.html',
  styleUrls: ['./blogdetail.component.css']
})
export class BlogdetailComponent implements OnInit {
  blogdata:any;
  title:any;
  short_description:any;
  image:any;
  description:any;
  comments:any;
  blog_comment:any;
  counts:any;
  recent:any;
  constructor(private route: ActivatedRoute,private router: Router,private blogService:BlogService,private common:CommonService) { }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
  ngOnInit(): void {
    let blog_id = this.route.snapshot.url[2].path; 
    this.blogService.getBlogDetail(blog_id).toPromise().then((bdata:any)=>{
      this.title=bdata.data.title;
      this.short_description=bdata.data.short_description;
      this.image=bdata.data.image;
      this.description=bdata.data.description;
      this.recent=bdata.recent;
    });
    this.blogService.getBlogComment(blog_id).toPromise().then((bc:any)=>{
      this.blog_comment=bc.data;
    });
  }
  onSubmit(commentPost:NgForm)
  {
    let data =  commentPost.value;

    let blog_id = this.route.snapshot.url[2].path; 
    if(data.name == '')
    {
      this.common.showError("","Name can not be blank");
    }
    if(data.email == '')
    {
      this.common.showError("","Email can not be blank");
    }
    if(data.comment == '')
    {
      this.common.showError("","Comment can not be blank");
    }
    this.blogService.saveBlogComment(blog_id,data).toPromise().then((blogdata:any)=>{
      if(blogdata.status == true)
      {
          this.common.showSuccess("","Comment send successfully");
          commentPost.reset();

      }
      else
      {
        this.common.showError("","Something went wrong");
      }
    });
  }
  gotoblogdetail(id:any)
  {
    this.router.navigate(['/blog/blog-details/'+id])
        .then(() => {
          window.location.reload();
        });
  }
}
