 <!-- code by rajan -->
 <div class="col-md-12 padding-fix-l20">
    <div class="new-product">
        <div class="sec-title">
            <h5>Recent Products</h5>
        </div>
        <div class="new-sliders owl-carousel">
            <div class="new-item" *ngFor="let tre of homepageproduct.trending">
                <!-- <div class="tab-heading">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a routerLink="#">{{tre.category}},</a></li>
                        <li class="list-inline-item"><a routerLink="#">{{tre.subcategory}}</a></li>
                    </ul>

                </div> -->
                <div class="new-img">
                    <a href="product-details/{{tre.id}}"><img style="width: 200.333px" class="main-img img-fluid" src="{{tre.image}}" alt=""></a>
                </div>
                <!-- <p class="txtlimit anotherpclass "><a style="font-size: 14px;" href="product-details/{{tre.id}}">{{tre.product_name}}</a></p> -->

                <div class="img-content d-flex justify-content-between">
                    <!-- <div class="p-price">
                        <ul class="list-unstyled list-inline price">
                            <li class="list-inline-item">₹{{tre.offer_price}}</li>
                            <li class="list-inline-item">
                                <p *ngIf="tre.offer_price != tre.price">₹{{tre.price}}</p>
                            </li>
                        </ul>
                    </div> -->
                    <div class="p-by">
                        <!-- <span class="it-fav" data-toggle="tooltip" data-placement="left" title="Favourite" (click)="addToWishlist(tre.id)"><i class="fa fa-heart-o" aria-hidden="true"></i></span> -->
                        <!-- <span data-toggle="tooltip" data-placement="top" title="Add to Cart" (click)="addToCart(tre.id)"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End code by rajan -->
