import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }
  getUserProfile(){
    let url = environment.base_url;

    var postData = {
      user_id:localStorage.getItem('user_id')
    }
    return this.http.post(url+'get-user-profile',postData);
  }
  getUserOrder(user_id:any)
  {
    let url = environment.base_url;

    var postData = {
      user_id:user_id
    }
    return this.http.post(url+'user-order',postData);
  }
  deleteaddress(address_id:any)
  {
    let url = environment.base_url;

    var postData = {
      address_id:address_id
    }
    return this.http.post(url+'remove-address',postData);
  }
  getAddressDetail(address_id:any)
  {
    let url = environment.base_url;

    var postData = {
      address_id:address_id
    }
    return this.http.post(url+'get-address-detail',postData);
  }
  getUserAddress(user_id:any)
  {
    let url = environment.base_url;

    var postData = {
      user_id:user_id
    }
    return this.http.post(url+'get-user-address',postData);
  }
  updateUserProfile(postData:any)
  {
    let url = environment.base_url;
    return this.http.post(url+'update-user-profile',postData);
  }
  getOrderDetail(order_id:any)
  {
    let url = environment.base_url;
    var postData = {
      order_id:order_id
    }
    return this.http.post(url+'order-detail',postData);
  }

  cancelOrder(order_id:string,user_id:string)
  {
    let url = environment.base_url;
    var postData = {
      orderid:order_id,
      user_id:user_id
    }
    return this.http.post(url+'cancel-order',postData);
  }

  addNewAddress(postData:any)
  {
    let url = environment.base_url;
    return this.http.post(url+'add-new-address',postData);
  }
  updateAddress(postData:any)
  {
    let url = environment.base_url;
    return this.http.post(url+'update-address',postData);
  }
}
