import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http:HttpClient,
    private router: Router,
    private common:CommonService
    ) { }
  saveContactUs(data:any){
  let url =environment.base_url

    this.http.post(url+"contact-us",data).toPromise().then((data:any)=>{
           this.common.showSuccess("",data.msg)
    })
  }
}
