<!-- Preloader -->
<!-- <div class="preloader">
    <div class="load-list">
        <div class="load"></div>
        <div class="load load2"></div>
    </div>
</div> -->
<!-- End Preloader -->
<link routerLink="https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900" rel="stylesheet">
<!-- Top Bar -->
<section class="top-bar" style="background: #eee;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-4">
                <div class="top-left d-flex">
                    <div class="lang-box">
                        <span *ngIf="isLoggedIn;" style="color: #333;
                                text-transform: capitalize;font-weight: bold;">
                                   Hi, {{user_name}}
                                </span>

                    </div>
                    <div class="mny-box">
                        <i class="fa fa-map-marker"></i> <span id="user_city" style="color:#333;"> </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8">
                <div class="top-right text-right">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">
                            <a routerLink="/my-account" *ngIf="isLoggedIn;"><img src="assets/images/user.png" alt="">My Account</a>
                        </li>
                        <li class="list-inline-item" *ngIf="isLoggedIn; else loggedOut" style="color: #fff;
                                text-transform: capitalize;">
                            <span style="cursor: pointer;color:#333;margin-left: 10px;" (click)="logout()">Logout</span>
                        </li>
                        <ng-template #loggedOut>
                            <li class="list-inline-item">
                                <a routerLink="/login"><img src="assets/images/login.png" alt="">Login</a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Top Bar -->

<!-- Logo Area -->
<section class="logo-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="logo">
                    <a (click)="gotoHome()" style="cursor: pointer;"><img src="assets/images/logonew.png" alt=""></a>
                </div>
            </div>
            <div class="col-md-5 col-sm-5 padding-fix">
                <form #searchPost="ngForm" (ngSubmit)="onSearch(searchPost.value)" class="search-bar" >
                    <input type="text" name="search_key" id="search_key"   placeholder="I'm looking for..." required ngModel>
                    <button type="submit"><i class="fa fa-search"></i></button>

                </form> 
                <!-- <div class="ng-autocomplete">
<ng-autocomplete 
[data]="data"
[searchKeyword]="keyword"
(selected)='selectEvent($event)'
(inputChanged)='onChangeSearch($event)'
(inputFocused)='onFocused($event)'
[itemTemplate]="itemTemplate"
[notFoundTemplate]="notFoundTemplate">                                 
</ng-autocomplete>

<ng-template #itemTemplate let-item>
<a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
<div [innerHTML]="notFound"></div>
</ng-template>
</div>
<button type="submit"><i class="fa fa-search"></i></button> -->

            </div>
            <div class="col-md-4">
                <div class="carts-area d-flex">
                    <div class="cart-box ml-auto text-center">
                        <a class="cart-btn" style="margin-right: 14px;" routerLink="/wishlist">
                            <img src="assets/images/heart.png" alt="cart">
                            <span>{{nwcount}}</span>
                        </a>
                        <a routerLink="/shopping_cart" data-toggle="tooltip" data-placement="top" title="Cart">
                            <img src="assets/images/cart.png" alt="favorite">
                            <span>{{nCcount}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Logo Area -->


<!-- Sticky Menu -->
<section class="sticky-menu">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 col-md-3">
                <div class="sticky-logo">
                    <a (click)="gotoHome()" style="cursor: pointer;"><img src="assets/images/logonew.png" alt="" class="img-fluid"></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-7">
                <div class="main-menu">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">
                            <a class="nav-link" (click)="gotoHome()" style="cursor: pointer;">Home </a>
                        </li>
                        <li class="list-inline-item" *ngFor="let items of menuData.data">
                            <a class="nav-link" routerLink="{{items.url}}">{{items.name}} </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-2">
                <div class="carts-area d-flex">
                    <div class="src-box">
                        <form #searchPost1="ngForm" (ngSubmit)="onSearch(searchPost1.value)"  (keyup)="getsearchlist(searchPost1.value);" >
                            <input type="text" name="search_key" placeholder="Search Here" ngModel required>
                            <button type="submit" name="button"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    <div class="cart-box ml-auto text-center">
                        <a routerLink="/wishlist" data-toggle="tooltip" data-placement="top" title="Wishlist">
                            <img src="assets/images/heart.png" alt="favorite">
                            <span>{{nwcount}}</span>
                        </a>
                    </div>
                    <div class="cart-box ml-4">
                        <a routerLink="/shopping_cart" data-toggle="tooltip" data-placement="top" title="Cart">
                            <img src="assets/images/cart.png" alt="favorite">
                            <span>{{nCcount}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Sticky Menu -->

<!-- Menu Area -->
<section class="menu-area">
    <div class="container-fluid">
        <div class="row menubar">
            <div class="col-md-12">
                <!-- <div class="main-menu"> -->
                    <!-- <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">
                            <a class="nav-link" (click)="gotoHome()" style="cursor: pointer;">Home </a>
                        </li>
                        <li class="list-inline-item" *ngFor="let item of menuData.data"><a class="nav-link" routerLink="{{item.url}}">{{item.name}} </a>
                        </li>
                    </ul> -->
                    <nav class="navbar navbar-expand-md navbar-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                          <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                          <ul class="navbar-nav">
                            <li class="nav-item active">
                              <a class="nav-link" (click)="gotoHome()" style="cursor: pointer;">Home</a>
                            </li>
                            <li class="nav-item" *ngFor="let item of menuData.data"><a class="nav-link" routerLink="{{item.url}}">{{item.name}} </a>
                            </li>
                            <li class="nav-item dropdown">
                              <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                All Category
                              </a>
                              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <!-- <li *ngFor="let item of categorydata"><a class="dropdown-item" href="#">{{item.category}}</a></li> -->
                                <li class="dropdown-submenu"  *ngFor="let item of categorydata">
                                    <a class="dropdown-item dropdown-toggle" routerLink="product-list/by-category/{{item.id}}">{{item.category}}</a>
                                  <ul class="dropdown-menu"  *ngIf="item.issubcategory">
                                    <li *ngFor="let subcategory of item.subcategory">
                                        <a class="dropdown-item" (click)="redirectProd(subcategory.id)" >{{subcategory.subcategory}}</a></li>
                                        <!-- routerLink="product-list/by-subcategory/{{subcategory.id}}" -->
                                    </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </nav>
                <!-- </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Menu Area -->

<!-- Mobile Menu -->
<!-- <section class="mobile-menu-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mobile-menu">
                            <nav id="dropdown">
                                <a (click)="gotoHome()" style="cursor: pointer;"><img src="assets/images/logonew.png" alt=""></a>
                                
                                <a routerLink="/login" *ngIf="isLoggedIn; else loggedOut"><span>Sign In</span></a>
                                <ng-template #loggedOut>
                                        <a routerLink="/login"><img src="assets/images/login.png" alt="">Login</a>
                                  </ng-template>
                                <ul class="list-unstyled">
                                    <li class="list-inline-item">
                                        <a class="nav-link" (click)="gotoHome()" style="cursor: pointer;">Home </a>
                                    </li>
                                    <li *ngFor="let itemss of menuData.data"><a routerLink="{{itemss.url}}">{{itemss.name}}</a>
                                    </li>

                                    <h2><i aria-hidden="true" class="fa fa-th-large"></i> All Categories</h2>

                                    <li *ngFor="let item of mcategorydata">
                                        <a routerLink="product-list/by-category/{{item.id}}">{{item.category}}</a>
                                        <ul class="dropdown list-unstyled">
                                                <li *ngFor="let subcategory of item.subcategory"><a href="product-list/by-subcategory/{{subcategory.id}}">{{subcategory.subcategory}}</a></li>
                                        </ul>
                                     </li>
                                    

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
<!-- End Mobile Menu -->

<nav class="navbar navbar-expand-sm navbar-dark mobile-nav" style=" padding-top: 0px;">
    <!-- <div class="mny-box">
                <span class="text-right">Login</span>
            </div> -->
    <div class="row" style=" background: #eeeeee; border-bottom: 1px solid #ddd;">
        <div class="col-6"><i class="fa fa-map-marker"></i> <span id="mobile_user_city" style="color:rgb(122, 21, 21);"> </span></div>

        <div class="col-3" *ngIf="isLoggedIn;" style="white-space: nowrap;"><a routerLink="/my-account">My Account</a></div>
        <div class="col-3 text-right responsives" *ngIf="!isLoggedIn;"><a routerLink="/login">Login</a></div>
        <div class="col-3 text-right responsives2">
            <a routerLink="/wishlist" data-toggle="tooltip" data-placement="top" title="Wishlist" style="padding-right: 5px;">
                <img class="cimg" src="assets/images/heart.png" alt="favorite">
                <span class="ccount">{{nwcount}}</span>
            </a>
            <a routerLink="/shopping_cart" data-toggle="tooltip" data-placement="top" title="Cart">
                <img class="cimg" src="assets/images/cart.png" alt="favorite">
                <span class="ccount">{{nCcount}}</span>
            </a>
        </div>
    </div>

    <!-- Sticky Menu -->

    <div class="container-fluid sticky-menu expandtime stickyanother">
        <div class="row">
            <!-- <div class="col-lg-2 col-md-3">
                <div class="sticky-logo">
                    <a (click)="gotoHome()" style="cursor: pointer;"><img src="assets/images/logonew.png" alt="" class="img-fluid"></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-7">
                <div class="main-menu">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item">
                            <a class="nav-link" (click)="gotoHome()" style="cursor: pointer;">Home </a>
                        </li>
                        <li class="list-inline-item" *ngFor="let items of menuData.data">
                            <a class="nav-link" routerLink="{{items.url}}">{{items.name}} </a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-3 col-md-2">
                <div class="carts-area d-flex">
                    <div class="src-box" style="position: absolute; padding-top: 15px;">
                        <form #searchPost12="ngForm" (ngSubmit)="onSearch(searchPost12.value)">
                            <input type="text" name="search_key" placeholder="Search Here" ngModel required style="height: 35px !important;">
                            <button type="submit" name="button"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    <!-- <div class="cart-box ml-auto text-center">
                        <a routerLink="/wishlist" data-toggle="tooltip" data-placement="top" title="Wishlist">
                            <img src="assets/images/heart.png" alt="favorite">
                            <span>{{nwcount}}</span>
                        </a>
                    </div>
                    <div class="cart-box ml-4">
                        <a routerLink="/shopping_cart" data-toggle="tooltip" data-placement="top" title="Cart">
                            <img src="assets/images/cart.png" alt="favorite">
                            <span>{{nCcount}}</span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- End Sticky Menu -->
    <a class="navbar-brand" href="#">
        <img src="assets/images/logonew1.png" alt="logo" style="width: 47px;">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapse_Navbar" >
                <span class="navbar-toggler-icon"></span>
            </button>

    <div class="collapse navbar-collapse right-menu" id="collapse_Navbar">
        <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let itemss of menuData.data">
                <a class="nav-link" href="{{itemss.url}}">{{itemss.name}}</a>
            </li>
            <li id="toggle_icon" onclick="showtoggle()">All Category <span id="toggleicon">+</span></li>
            <li class="nav-item dropdown toggle_text" *ngFor="let item of mcategorydata" style="display: none;">
                <a href="product-list/by-category/{{item.id}}" *ngIf="item.issubcategory" class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown">
                            {{item.category}}
                        </a>
                <a *ngIf="!item.issubcategory" class="nav-link" href="#" id="navbardrop">
                                {{item.category}}
                        </a>
                <div class="dropdown-menu" *ngIf="item.issubcategory">
                    <a class="dropdown-item"   (click)="redirectProd(subcategory.id)"  *ngFor="let subcategory of item.subcategory">{{subcategory.subcategory}}</a>
                </div>
            </li>
        </ul>
    </div>
</nav>
