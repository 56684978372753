import { Component, OnInit } from '@angular/core';
import {ContactService} from '../service/contact.service';
import { CommonService } from '../service/common.service';
import {NgForm} from '@angular/forms';
import {FooterService} from '../service/footer.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  constructor(private contactService:ContactService,private common:CommonService,private footerService:FooterService) { }
  address:any;
  email:any;
  phone:any;
  facebook_link:any;
  twitter_link:any;
  linkdin_link:any;
  ngOnInit(): void {
    this.footerService.getFooterData().toPromise().then((data:any)=>{
      console.log(data);
      this.address=data.company[0].address;
      this.email=data.company[0].email;
      this.phone=data.company[0].phone;
      this.facebook_link=data.company[0].facebook_link;
      this.twitter_link=data.company[0].twitter_link;
      this.linkdin_link=data.company[0].linkdin_link;
    });
  }
  validateEmail(email:any) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
  onSubmit(postdata: NgForm)
  {
    let data =  postdata.value;
    if(!this.validateEmail(data.email))
    {
        this.common.showError("","Invalid email");
        return;
    }
    if(data.name == '')
    {
      this.common.showError("",'Name can not be blank');
    }
    else if(data.email == '')
    {
      this.common.showError("",'Email can not be blank');
    }
    else if(data.subject == '')
    {
      this.common.showError("",'Subject can not be blank');
    }
    else if(data.message == '')
    {
      this.common.showError("",'Message can not be blank');
    }
    else
    {
      let pData = {
        name:data.name,
        email:data.email,
        subject:data.subject,
        message:data.message,
      }
       this.contactService.saveContactUs(pData);
       postdata.reset();
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
}
