<!-- Breadcrumb Area -->
<section class="breadcrumb-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="breadcrumb-box text-center">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><a href="#">Home</a></li>
            <li class="list-inline-item"><span>||</span> All Sellers</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Breadcrumb Area -->

<!-- Shopping Cart -->
<section class="shopping-cart">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="cart-table table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="t-pro cartpname">Seller</th>
                <th class="t-price cartpname">Price</th>
                <th class="t-qty cartpname">Delivery</th>
                <th class="t-rem cartpname"></th>
              </tr>
            </thead>
            <tbody *ngFor="let seller of sellersList">
              <tr>
                <td style="float: left" class="">
                  <div>
                    {{ seller?.vendor_name }}
                  </div>
                </td>
                <td class="">
                  <div>
                    {{ seller?.vendor_offer_price }}
                  </div>
                </td>

                <td class="">
                  <div>Order will be delivered within 7-8 working days.</div>
                </td>
                <td>
                  <div class="btn-section" *ngIf="checkStock(seller?.vendor_stock)==true">
                    <button
                      type="button"
                      class="cart-btns cart btn btn-warning btn-warning1 btn-addTo-Cart"
                      style="margin-right: 10px"
                      (click)="addToCart(1, seller)"
                    >
                      Add to Cart
                    </button>
                    <button
                      class="cart btn btn-warning"
                      style="margin-right: 10px"
                      type="button"
                      class="btn-addTo-buy cart btn btn-warning"
                      routerLink="/checkout"
                    >
                      Buy Now
                    </button>
                  </div>
<div class="btn-section" *ngIf="checkStock(seller?.vendor_stock)==false">
<span>Out Stock</span>
</div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Shopping Cart -->
