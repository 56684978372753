import { Component, OnInit } from '@angular/core';
import {HomepageService} from '../service/homepage.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  contentdata:any;

  constructor(private homepageService:HomepageService) { }

  ngOnInit(): void {
    this.homepageService.getPrivacyPolicy().toPromise().then((hdata:any)=>{
      this.contentdata=hdata.data[0]['content'];
    });
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }
}
