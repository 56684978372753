        <!-- Footer Area -->
        <!-- <div class="f-social">
            <ul>
                <li><a routerLink="{{facebook_link}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                <li><a routerLink="{{twitter_link}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                <li><a routerLink="{{linkdin_link}}" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            </ul>
        </div>-->
        <section class="footer-top">
            <div class="container">
                
                <div class="row">
                    <div class="col-md-3">
                        <div class="f-cat">
                            <img src="assets/images/logonew.png" alt="" class="img-fluid">
                            <p>{{company_name}}</p>
                            </div>
                    </div>
                    <div class="col-md-3">
                        <div class="f-cat f-cate">
                            <h5>Categories</h5>
                            <ul class="list-unstyled">
                                <li *ngFor="let fot of footerdata?.category">
                                    <a (click)="onNavigate(fot.id)" style="cursor: pointer;"><i class="fa fa-angle-right"></i>{{fot.category}}</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <div class="f-link">
                            <h5>Quick Links</h5>
                            <ul class="list-unstyled">
                                <li *ngIf="!isLoggedIn;"><a routerLink="login"><i class="fa fa-angle-right"></i>Log In</a></li>
                                <li *ngIf="isLoggedIn;"><a routerLink="/my-account"><i class="fa fa-angle-right"></i>My Account</a></li>
                                <li><a routerLink="contactus"><i class="fa fa-angle-right"></i>Contact Us</a></li>
                                <li><a routerLink="shipping-policy"><i class="fa fa-angle-right"></i>Shipping Policy</a></li>
                                <li><a routerLink="privacy-policy"><i class="fa fa-angle-right"></i>Privacy Policy</a></li>
                                <li><a routerLink="term-condition"><i class="fa fa-angle-right"></i>Terms & Condition</a></li>
                                <li><a routerLink="vender-Register"><i class="fa fa-angle-right"></i>Register As Vendor</a></li>
                                <li><a routerLink="refund-policy"><i class="fa fa-angle-right"></i>Refund Policy</a></li>
                                <!-- <li><a routerLink="vender-Register"><i class="fa fa-angle-right"></i>Register As Vendor</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">

                        <div class="f-contact">
                            <h5>Contact Info</h5>
                            <div class="f-add">

                                <p><i class="fa fa-map-marker"></i>
                                    <span>Address : </span>{{address}}</p>
                            </div>
                            <div class="f-email">

                                <p> <i class="fa fa-envelope"></i>
                                    <span>Email : </span>{{email}}</p>
                            </div>
                            <div class="f-phn">

                                <p>  <i class="fa fa-phone"></i>
                                    <span>Phone : </span>{{phone}}</p>
                            </div>
                          <div class="payment-link">
                            <h4>Safe Payments</h4>
                            <ul>

                                <li><i class="fa fa-cc-visa" aria-hidden="true"></i></li>
                                <li><i class="fa fa-cc-stripe" aria-hidden="true"></i></li>
                                <li><i class="fa fa-cc-paypal" aria-hidden="true"></i></li>
                                <li><i class="fa fa-cc-mastercard" aria-hidden="true"></i></li>
                            </ul>

                            <div class="float-left middle">
                                <a class="btn2" href="{{facebook_link}}" target="_blank"> <i style="color: #c0bcbc" class="fa fa-facebook-f"></i> </a>
                                 <a class="btn2" href="{{twitter_link}}" target="_blank"> <i style="color: #c0bcbc" class="fa fa-twitter"></i> </a>
                                  <a class="btn2" href="{{linkdin_link}}" target="_blank"> <i style="color: #c0bcbc" class="fa fa-linkedin"></i> </a>
                                   <a class="btn2" href="https://www.instagram.com/localkornerr/" target="_blank"> <i style="color: #c0bcbc" class="fa fa-instagram"></i> </a>
                                 </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="footer-btm">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p>Copyright &copy; 2021 | Designed With <i class="fa fa-heart"></i> Made by <a href="http://maydtechnologies.com/" target="_blank" style="font-size: 12px;">Mayd Technologies</a></p>
                    </div>

                </div>
            </div>
            <a class="whatsapp " href="https://api.whatsapp.com/send?phone=917311173173&text=Hello,%20I%20have%20a%20question%20about" title="click to open whatsapp chat"><button class="btn btn-success" style="bottom: 13px;position: fixed;">
                <i class="fa fa-whatsapp fa-4x" aria-hidden="true"></i>
                </button></a>
            <div class="back-to-top text-center">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
            </div>
        </section>


