import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import {ProductsService} from '../service/products.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})

export class WishlistComponent implements OnInit {
  wishlist:any;
  isLoggedIn:any;
  showBlank:any;
  constructor(private productsService:ProductsService,
    private common:CommonService) { }

  ngOnInit(): void {
    this.getWishlist();
  }
  getWishlist()
  {
    let wlength=0;

    this.productsService.getWishlist().toPromise().then((data:any)=>{
      this.wishlist=data.data;
       wlength = Object.keys(data.data).length;
      this.productsService.Wcount.next(wlength)

      if (localStorage.getItem("user_id") === null || wlength == 0)
      {
          this.showBlank = false;
      }
      else
      {
        this.showBlank = true;
      }
    });

  }
  moveToCart(wid:any)
  {
    this.productsService.moveToCart(wid).toPromise().then((data:any)=>{
      console.log(data);
      this.common.showSuccess("",data.msg);
      this.productsService.cartCountSource.next(data.cart_qty);
      this.getWishlist();
    });
  }
  removeFromWishlist(wid:any)
  {
    this.productsService.removeFromWishlist(wid).toPromise().then((data:any)=>{
      this.common.showSuccess("",data.msg)
      // this.productsService.decreasWcount()
      this.getWishlist();
    });
  }

}
