import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactusComponent } from './contactus/contactus.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { VerifyotpComponent } from './verifyotp/verifyotp.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component'
import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
import { PaymentComponent } from './payment/payment.component';
import { TermconditionComponent } from './termcondition/termcondition.component';
import { ShippingpolicyComponent } from './shippingpolicy/shippingpolicy.component';
import { PaymentstatusComponent } from './paymentstatus/paymentstatus.component';
import {UserprofileComponent} from './userprofile/userprofile.component';
import {BlogComponent} from './blog/blog.component';
import {BlogdetailComponent} from './blogdetail/blogdetail.component';
import {PrivacypolicyComponent} from './privacypolicy/privacypolicy.component';
import { VenderRegisterComponent } from './vender-register/vender-register.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
import { AllSellersListComponent } from './all-sellers-list/all-sellers-list.component';


const routes: Routes = [
  { path:"refund-policy" , component:RefundpolicyComponent},
  { path:"vender-Register" , component:VenderRegisterComponent},
  { path: 'blog/blog-details/:id', component:BlogdetailComponent},
  { path: 'blog', component:BlogComponent},
  { path: 'my-account', component:UserprofileComponent},
  { path: 'order-status', component:PaymentstatusComponent},
  { path: 'shipping-policy', component:ShippingpolicyComponent},
  { path: 'term-condition', component:TermconditionComponent},
  { path: 'privacy-policy', component:PrivacypolicyComponent},
  { path: 'payment', component:PaymentComponent},
  { path: 'shopping_cart', component:ShoppingcartComponent},
  { path: 'product-details/:id', component:ProductDetailsComponent},
  { path: 'allSellersList', component:AllSellersListComponent},
  { path: 'product-list/by-subcategory/:id', component:ProductListComponent},
  { path: 'product-list/by-category/:id', component:ProductListComponent},
  { path: 'product-list/best-seller', component:ProductListComponent},
  { path: 'product-list/todays-deal', component:ProductListComponent},
  { path: 'product-list/fashion', component:ProductListComponent},
  { path: 'product-list/search/:search_key', component:ProductListComponent},
  { path: 'verifyotp', component:VerifyotpComponent},
  { path: 'forget-password', component:ForgetpasswordComponent},
  { path: 'change-password', component:ChangepasswordComponent},
  { path: 'wishlist', component:WishlistComponent},
  { path: 'myaccount', component:MyaccountComponent},
  { path: 'checkout', component:CheckoutComponent},
  { path: 'register', component:RegisterComponent},
  { path: 'login', component:LoginComponent},
  { path: 'contactus', component: ContactusComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'home', component: HomepageComponent },
  { path: '', component: HomepageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
