<style>
    .tab-menu {
        flex-direction: column;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-left: 0;
        list-style: none;
        padding: 0px;
        background: #e3e3e3;
    }

    .tab-menu a.anchor {
        color: black;
    height: auto;
    display: inline-block;
    width: 100%;
    text-align: left;
    padding: 15px 15px;
    }
    .tab-menu a.anchor:hover{
        color: #fff;
    }

    .btn:hover {
        background-color: #ff9800;
    }

    .tab-menu a {
    border-bottom: none;
    color: #333333;
    font-weight: 500;
    font-size: 12px;
    display: block;
    padding: 10px;
    text-transform: uppercase;
    }

    a {
        transition: all 0.3s ease 0s;
    }

    .tab-content {
        width: 100%;
        border: 1px solid #eeeeee;
        color: #333333;
        display: block;
        padding: 15px 15px 13px;
    }

    .tab-content .tab-pane {
        display: block;
    }

    .tab-content .tab-pane.anchor {
        height: auto;
        visibility: visible;
        opacity: 1;
        overflow: visible;
    }

    .fa {
        padding-right: 2px;
    }

    .order-info {
        border: 1px solid lightgrey;
        border-bottom: 2px solid lightgrey;
        border-radius: 8px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;


    }
    .nav:last-child{
        border: 0px;;
    }

    .btn {
        width: 170px;
        padding: 10px;
        border: 0px solid #ff9800;
        border-radius: 0;
    }

    .details form input {
        display: block;
        width: 100%;
        border: 1px solid #ebebeb;
        border-radius: 0;
        line-height: 24px;
        padding: 11px 25px;
        color: #656565;
    }

    .widd {
        padding-left: 20px;
    }

    .btn-add {
        width: 100px;
    }

    .btn-addd {
        width: 140px;
    }

    /* .btn-new{
        width: 160px;
    } */
    .btn-addd button {
        padding-left: 20px;
    }
    .card {
    margin: 10px 0px;
}
</style>
    <div id="accordion" class="container">
        <div class="card">
            <div class="row">
                <div class="col-lg-2" style="background-color: rgb(238, 236, 236);">
                    <div class="card-header tab-menu nav" id="headingOne">
                        <h5 class="mb-0">
                            <a class="btn anchor" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne">
                                <p class="float-left">
                                    <i class="fa fa-dashcube" aria-hidden="true"></i>
                                    Dashboard
                                </p>
                            </a>
                        </h5>
                    </div>
                    <div class="card-header tab-menu nav" id="headingTwo">
                        <h5 class="mb-0">
                            <a class="btn collapsed anchor" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo" (click)="getUserOrder()">
                                <p class="float-left">
                                    <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                                    ORDERS
                                </p>
                            </a>
                        </h5>
                    </div>
                    <div class="card-header tab-menu nav" id="headingThree">
                        <h5 class="mb-0">
                            <a class="btn collapsed anchor" data-toggle="collapse" data-target="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree" (click)="getUserAddress()">
                                <p class="float-left">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    ADDRESS
                                </p>
                            </a>
                        </h5>
                    </div>
                    <div class="card-header tab-menu nav" id="headingFour">
                        <h5 class="mb-0">
                            <a class="btn collapsed anchor" data-toggle="collapse" data-target="#collapseFour"
                                aria-expanded="false" aria-controls="collapseFour" (click)="getUserProfile()">
                                <p class="float-left">
                                    <i class="fa fa-user" aria-hidden="true"></i>ACCOUNT DETAILS
                                </p>
                            </a>
                        </h5>
                    </div>
                    <div class="card-header tab-menu nav" id="headingFive">
                        <h5 class="mb-0">
                            <a class="btn collapsed anchor" data-toggle="collapse" data-target="#collapseFive"
                                aria-expanded="false" aria-controls="collapseFive" (click)="logout()">
                                <p class="float-left">
                                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                                    LOGOUT
                                </p>
                            </a>
                        </h5>
                    </div>
                </div>
                <div class="col-lg-10">
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <div class="tab-pane fade show anchor" id="dashboard" role="tabpanel">
                                <div class="column-content">
                                    <h3>Dashboard</h3>
                                    <div class="hello">
                                        <p>
                                            Hello
                                            <strong>{{user_name}}</strong>
                                            (If not
                                            <strong>{{user_name}}</strong>
                                            <span class="logout" style="cursor: pointer;" (click)="logout()"> Logout</span>
                                            )
                                        </p>
                                        <p>Welcome to the dashboard, I hope you have no trouble here in looking at your order details, address management, and changing your password, welcome onboard.</p>
                                    </div>
                                    <p class="mb-0">
                                        thank you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            <div class="tab-pane fade anchor show" id="orders" role="tabpanel"
                                data-mdb-toggle="collapse" data-mdb-target="#collapseExample" aria-expanded="true"
                                aria-controls="collapseExample">
                                <div class="column-content" id="collapseExample">
                                    <h3>Orders</h3>
                                    <div class="myaccount-table table-responsive text-center">
                                        <table class="table table-bordered p-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Order No</th>
                                                    <th>Order Date</th>
                                                    <th>Grand Total</th>
                                                    <th>Payment Method</th>
                                                    <th>Order Status</th>
                                                    <th>Invoice</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let ord of orderlist">
                                                    <td><b>{{ord.order_id}}</b></td>
                                                    <td>{{ord.order_date}}</td>
                                                    <td>{{ord.grand_total}}</td>
                                                    <td>{{ord.payment_method}}</td>
                                                    <td>{{ord.order_status}}</td>
                                                    <td *ngIf="!ord.invoice_no">Not Generated</td>
                                                    <td *ngIf="ord.invoice_no"><a class="btn btn-success btn-sm" href="{{ord.invoice_file}}" target="_blank" download>Download</a>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-add p-2 btn-view" data-toggle="modal"
                                                            data-target="#exampleModalCenter"
                                                             (click)="getOrderDetail(ord.id)">
                                                            View
                                                        </button>
                                                        <!--  -->
                                                        <button (click)="cancelOrder(ord.order_id)"
                                                        class="btn btn-add p-2 btn-cancel" 
                                                            >
                                                            Cancel Order
                                                        </button>
                                                        <div class="modal fade" id="exampleModalCenter" tabindex="-1"
                                                            role="dialog" aria-labelledby="exampleModalCenterTitle"
                                                            aria-hidden="true">
                                                            <div class="modal-dialog modal-lg modal-dialog-centered"
                                                                role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-body">
                                                                        <div class="container">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <h3 class="text-center">Order Detail</h3>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row p-3 order-info" style="border: 1px solid lightgrey; border-bottom: 2px solid lightgrey; border-radius: 8px;">
                                                                                <div class="col-lg-6">
                                                                                    <table class="">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Order No</td>
                                                                                                <td>{{order_id}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Transaction Id</td>
                                                                                                <td>{{payment_id}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Coupon code</td>
                                                                                                <td>{{coupon_code}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Order Status</td>
                                                                                                <td>{{order_status}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div class="col-lg-6">
                                                                                    <table class="">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Amount</td>
                                                                                                <td>{{grand_total}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Coupon discount</td>
                                                                                                <td>{{coupon_amount}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Shipping charge</td>
                                                                                                <td>{{shipping_charge}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row p-5"
                                                                                style="border: 1px solid lightgrey;border-bottom: 2px solid lightgrey; border-radius: 8px;">
                                                                                <h3>Address</h3>
                                                                                <div class="col-lg-12 bg-light">
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th class="w-25"></th>
                                                                                                <th class="w-50"></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Address 1 :</td>
                                                                                                <td>{{fname}} {{lname}},
                                                                                                    {{address}}, {{city}},
                                                                                                    {{state}},
                                                                                                    India, {{zip}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Phone No. :</td>
                                                                                                <td>{{phone}} </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Email. :</td>
                                                                                                <td>{{email}} </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row p-5"
                                                                                style="border: 1px solid lightgrey; border-radius: 8px;">
                                                                                <div class="col-lg-12">
                                                                                    <table class="table table-bordered">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Prduct Name</th>
                                                                                                <th>Qty</th>
                                                                                                <th>Product price</th>
                                                                                                <th>Offer Price</th>
                                                                                                <th>Grand Total</th>
                                                                                                <th>Image</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="bg-light" *ngFor="let op of order_product">
                                                                                                <td>{{op.product_name}}</td>
                                                                                                <td>{{op.qty}}</td>
                                                                                                <td>{{op.product_price}}</td>
                                                                                                <td>{{op.offer_price}}</td>
                                                                                                <td>{{op.grand_total}}</td>
                                                                                                <td>
                                                                                                    <img src="{{op.product_image}}" style="width: 50px;height: 50px;">
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">
                            <div class="card">
                                <div class="card-header">
                                    Shipping Address
                                    <!-- <div class="btn float-right btn-add" style="background-color: #ff9800;">ADD NEW
                                    </div> -->
                                    <button type="button" class="btn float-right btn-add btn-new" data-toggle="modal"
                                        data-target=".bd-example-modal-lg" style="background-color: #ff9800;">ADD
                                        NEW</button>
                                </div>

                                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                                    aria-labelledby="myLargeModalLabel" aria-hidden="true" id="addressmodal">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="card-body">
                                                <div class="card w-100">
                                                    <div class="details p-3">
                                                        <div class="card-body bg-light">
                                                            <h2 class="card-title">Add new shipping address</h2>
                                                        </div>
                                                        <form #addressPost="ngForm"  (ngSubmit)="addNewAddress(addressPost)">
                                                            <div class="row">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="fname"
                                                                        placeholder="First Name" ngModel required>
                                                                </div>
                                                                <input type="hidden" ngModel="{{user_id}}" name="ship_user_id">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="lname"
                                                                        placeholder="Last Name" ngModel required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="email"
                                                                        placeholder="Email" ngModel>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="phone"
                                                                        placeholder="Phone" ngModel required>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <input type="text" name="address"
                                                                        placeholder="Address" id="address" ngModel required onblur="getLatLong();">
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                
                                                                    <select name="state" class="form-control" id="State" ngModel="0" (ngModelChange)="changeState($event)"   placeholder="State" required>
                                                                        <option [value]="0" selected>Select State</option>
                                                                        <option *ngFor="let state of stateList" [value]="state.id" >{{state.name}}</option>
                                                                    </select>
                                                                    <!-- <input type="text" name="state"
                                                                        placeholder="State" ngModel required> -->
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                
                                                                    <select name="city" class="form-control" id="city"  placeholder="city" ngModel="0" required>
                                                                        <option [value]="0" selected>Select City</option>
                                                                        <option *ngFor="let city of cityList" [value]="city.id" >{{city.city}}</option>
                                                                    </select>
                                                                    <!-- <input type="text" name="city"
                                                                        placeholder="City" id="city" ngModel required onblur="getLatLong();"> -->
                                                                </div>
                                                               
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" placeholder="Zip" name="zip" ngModel required>
                                                                </div>
                                                                
                                                                <div class="col-lg-6 mt-3">
                                                                    <select (ngModelChange)="changeAddType($event)" id="address_type" name="address_type" class="form-control"  ngModel="0" required>
                                                                        AddressTypeList
                                                                    
                                                                        <option [value]="0" selected>Select Address Type</option>
                                                                        <option *ngFor="let type of AddressTypeList" [value]="type.id" >{{type.address_type}}</option>
                                                                        <!-- <option value="1" selected>Home</option> -->
                                                                        <!-- <option value="2">Office</option> -->
                                                                        <!-- <option value="3">Others</option> -->
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-6 mt-3" *ngIf="addressOther">
                                                                    <input type="text" placeholder="Others Address" name="addressOther" ngModel required>
                                                                   
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <input type="hidden" id="nuser_lat" name="latitude" ngModel>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <input type="hidden" id="nuser_long" name="longitude" ngModel>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <button class="btn mt-2 btn-addd"
                                                                    style="background-color: #ff9800;">
                                                                    Save changes
                                                                </button>
                                    <button class="btn mt-2 btn-addd btn-danger pull-right" (click)="resetForm(addressPost)" data-dismiss="modal" aria-label="Close">Cancel</button>
                                
                                                                </div>
                                
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <!-- <div class="details p-3">
                                                        <div class="card-body bg-light">
                                                            <h5 class="card-title">Add new shipping address</h5>
                                                        </div>
                                                        <form #addressPost="ngForm" (ngSubmit)="addNewAddress(addressPost)">
                                                            <div class="row">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="fname"
                                                                        placeholder="First Name" ngModel required>
                                                                </div>
                                                                <input type="hidden" ngModel="{{user_id}}" name="ship_user_id">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="lname"
                                                                        placeholder="Last Name" ngModel required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="email"
                                                                        placeholder="Email" ngModel required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="phone"
                                                                        placeholder="Phone" ngModel required>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <input type="text" name="address"
                                                                        placeholder="Address" id="naddress" ngModel required onblur="getLatLongForMyaccount();">
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <input type="text" name="city"
                                                                        placeholder="City" id="ncity" ngModel required onblur="getLatLongForMyaccount();">
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="state"
                                                                        placeholder="State" ngModel required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" placeholder="Zip" name="zip" ngModel required>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <input type="hidden" id="nuser_lat" name="latitude" ngModel>
                                                              </div>
                                                              <div class="form-group col-md-6">
                                                                    <input type="hidden" id="nuser_long" name="longitude" ngModel>
                                                              </div>
                                                                <div class="col-lg-4 mt-3">
                                                                    <select name="address_type" class="form-control"  ngModel="1" required>
                                                                        <option value="1" selected>Home</option>
                                                                        <option value="2">Office</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <button class="btn mt-2 btn-addd"
                                                                    style="background-color: #ff9800;">
                                                                    Save changes
                                                                </button>
                                            <button class="btn mt-2 btn-addd btn-danger pull-right" data-dismiss="modal" aria-label="Close">cancel</button>

                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal" id="myModal">
                                  <div class="modal-dialog">
                                    <div class="modal-content">

                                      <!-- Modal Header -->
                                      <div class="modal-header">
                                        <h4 class="modal-title">Update Address</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                      </div>

                                      <!-- Modal body -->
                                      <div class="modal-body">
                                                    <div class="details p-3">
                                                        <form #addressPost1="ngForm" (ngSubmit)="updateAddress(addressPost1)">
                                                            <div class="row">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="fname"
                                                                        placeholder="First Name" ngModel="{{fname}}" required>
                                                                </div>
                                                                <input type="hidden" ngModel="{{address_id}}" name="ship_address_id">
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="lname"
                                                                        placeholder="Last Name" ngModel="{{lname}}" required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="email"
                                                                        placeholder="Email" ngModel="{{email}}">
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="phone"
                                                                        placeholder="Phone" ngModel="{{phone}}" required>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <input type="text" name="address"
                                                                        placeholder="Address" id="uaddress" ngModel="{{address}}" required onblur="getLatLongForMyaccount1();">
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <input type="text" name="city"
                                                                        placeholder="City" id="ucity" ngModel="{{city}}" required onblur="getLatLongForMyaccount1();">
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" name="state"
                                                                        placeholder="State" ngModel="{{state}}" required>
                                                                </div>
                                                                <div class="col-lg-6 mt-3">
                                                                    <input type="text" placeholder="Zip" name="zip" ngModel="{{zip}}" required>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <input type="hidden" id="uuser_lat" name="latitude" ngModel="{{latitude}}">
                                                              </div>
                                                              <div class="form-group col-md-6">
                                                                    <input type="hidden" id="uuser_long" name="longitude" ngModel="{{longitude}}">
                                                              </div>
                                                                <div class="col-lg-4 mt-3">
                                                                    <select name="address_type" class="form-control"  ngModel="1" required>
                                                                        <option value="1" selected>Home</option>
                                                                        <option value="2">Office</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-12 mt-3">
                                                                    <button class="btn mt-2 btn-addd"
                                                                    style="background-color: #ff9800;">
                                                                    Save changes
                                                                </button>

                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>
                                      </div>

                                      <!-- Modal footer -->
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                                <div class="card-body mt-3" *ngFor="let ua of useraddress">
                                    <div class="card w-100">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h5 class="card-title">{{ua.fname}} {{ua.lname}}</h5>
                                                </div>
                                                <div class="col-md-6 text-right">
                                                    <span class="badge badge-info" style="margin-right: 10px;cursor: pointer;" (click)="get_address_detail(ua.id)" data-toggle="modal" data-target="#myModal">Edit</span>
                                                    <span class="badge badge-danger" style="cursor: pointer;" (click)="delete_address(ua.id)">Delete</span>
                                                </div>
                                            </div>
                                            <p class="card-text">{{ua.address}} , {{ua.city}}, {{ua.state}} , {{ua.zip}} Mobile: {{ua.phone}} , Email : {{ua.email}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                        <div class="card-body">
                            <div class="card w-100">

                                <div class="details p-3">
                                    <div class="card-body">
                                        <h5 class="card-title">{{fname}} {{lname}}</h5>
                                    </div>
                                    <form #profilePost="ngForm" (ngSubmit)="onUpdate(profilePost.value)">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <input type="text" name="fname" placeholder="Name" ngModel="{{fname}}">
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" name="lname" placeholder="Last Name" ngModel="{{lname}}">
                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <input type="text" name="phone" placeholder="Phone no" ngModel="{{phone}}">
                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <input type="email" name="email" placeholder="Email Address" ngModel="{{email}}" >
                                            </div>
                                            <input type="hidden" ngModel="{{userid}}" name="user_id">
                                            <div class="col-lg-12 mt-3">
                                                <b>Change Password :</b>
                                            </div>
                                            <hr>
                                            <div class="card-body">
                                                <h5 class="card-title">Password</h5>
                                            </div>
                                            <div class="col-lg-12">
                                                <input type="password" id="pword" name="pwd" placeholder="Password"
                                                ngModel="{{password}}">
                                            </div>
                                            <div class="col-lg-6 mt-3">
                                                <input type="text" placeholder="New password" name="new_password" ngModel>
                                            </div>
                                            <div class="col-lg-6 mt-3">
                                                <input type="text" placeholder="Confirm password" name="new_confirm_password" ngModel>
                                            </div>
                                            <button class="btn mt-2 btn-addd" style="background-color: #ff9800;">
                                                Save changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                        <div class="card-body">
                            <a href="#">You are now logout</a>
                        </div>
                    </div>



                </div>
            </div>
