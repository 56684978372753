import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paymentstatus',
  templateUrl: './paymentstatus.component.html',
  styleUrls: ['./paymentstatus.component.css']
})
export class PaymentstatusComponent implements OnInit {
  order_no:any;
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.order_no=localStorage.getItem("order_no");
  }
  gotoHome()
  {
    this.router.navigate(['/'])
        .then(() => {
          window.location.reload();
        });
  }
}
